import type { Address, CreateOrderRequest, ISO4217, OrderItem } from '@samsonvt/shared-types/orderLambda';
import type { CartEntry } from 'src/providers/ShoppingCart';

export interface RawFormState {
  firstName: string;
  lastName: string;
  companyName: string;
  telephone: string;
  buildingName?: string;
  streetName: string;
  city: string;
  postalCode: string;
  country: string;
  saveDetails: boolean;
  customerReference?: string;
  messageToSeller?: string;
  'vtaas-ui:checkout:customerAccountField'?: string;
}

export const serialiseOrderData = (
  formState: RawFormState,
  cart: CartEntry[],
  currency: ISO4217,
  discountPercentage: number | undefined
) => {
  const serialisedAddress: Address = {
    address1: formState.buildingName,
    address2: formState.streetName,
    city: formState.city,
    postalCode: formState.postalCode,
    country: formState.country,
  };

  const serialisedOrderItems: OrderItem[] = cart.map((item) => ({
    partName: item.partName,
    partNumber: item.partNumber,
    productId: item.productId,
    unitPrice: item.unitPrice,
    quantity: item.quantity,
    currency,
    eCommerceId: item.eCommerceId,
  }));

  const newOrder: CreateOrderRequest = {
    firstName: formState.firstName,
    lastName: formState.lastName,
    telephone: formState.telephone,
    companyName: formState.companyName,
    customerReference: formState.customerReference,
    messageToSeller: formState.messageToSeller,
    address: serialisedAddress,
    items: serialisedOrderItems,
    discount: discountPercentage ?? 0,
    'vtaas-ui:checkout:customerAccountField': formState['vtaas-ui:checkout:customerAccountField'],
  };

  return newOrder;
};
