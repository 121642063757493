import { styled } from '@mui/material';
import MUIBox from '@mui/material/Box';
import MUITypography from '@mui/material/Typography';
import { Order } from '@samsonvt/shared-types/orderLambda';
import useTranslation from 'src/hooks/useTranslation';
import { formatDateWithTime } from 'src/services/formatDate';
import ReceivedOrderPartsList from '../OrderDetailsPage/ReceivedOrderPartsList';
import {
  OrderDetailsData,
  OrderDetailsDataCell,
  OrderDetailsDataHeader,
  OrderDetailsDataRow,
  OrderDetailsDivider,
  OrderDetailsHeader,
  OrderDetailsLeftPanel,
  OrderDetailsMainContent,
  OrderDetailsPanel,
  OrderDetailsPanelTitle,
  OrderDetailsSection,
  OrderDetailsTitleContainer,
} from '../OrderDetailsPage/styles';

export function ExportPdfOrderDetails({ order }: { order: Order }) {
  const {
    orderId,
    eCommerceOrderName,
    firstName,
    lastName,
    companyName,
    telephone,
    customerReference,
    messageToSeller,
    items,
    createdByEmail: email,
    timestamp,
    totalPrice,
  } = order;

  const { country, city, address1, address2, postalCode } = order.address;
  const customerAccountFieldValue = order['vtaas-ui:checkout:customerAccountField'];
  const customerAccountFieldLabel = `${useTranslation('vtaas-ui:checkout:customerAccountField', 'Customer account')}:`;

  return (
    <OrderDetailsPrintContainer>
      <OrderDetailsHeader>
        <OrderDetailsTitleContainer>
          <MUITypography variant="h5">Order details for {eCommerceOrderName || orderId}</MUITypography>
          <MUITypography variant="body1">Order received: {formatDateWithTime(timestamp)} </MUITypography>
        </OrderDetailsTitleContainer>
      </OrderDetailsHeader>
      <PrintOrderDetailsMainContent>
        <PrintOrderDetailsLeftPanel>
          <PrintDetailsAndAddress>
            <PrintOrderDetailsSection>
              <OrderDetailsPanelTitle>Customer details</OrderDetailsPanelTitle>
              <OrderDetailsData>
                <OrderDetailsDataRow>
                  <PrintOrderDetailsDataHeader>First name:</PrintOrderDetailsDataHeader>
                  <PrintOrderDetailsDataCell>{firstName}</PrintOrderDetailsDataCell>
                </OrderDetailsDataRow>
                <OrderDetailsDataRow>
                  <PrintOrderDetailsDataHeader>Last name:</PrintOrderDetailsDataHeader>
                  <PrintOrderDetailsDataCell>{lastName}</PrintOrderDetailsDataCell>
                </OrderDetailsDataRow>
                <OrderDetailsDataRow>
                  <PrintOrderDetailsDataHeader>Company name:</PrintOrderDetailsDataHeader>
                  <PrintOrderDetailsDataCell>{companyName}</PrintOrderDetailsDataCell>
                </OrderDetailsDataRow>
                <OrderDetailsDataRow>
                  <PrintOrderDetailsDataHeader>Telephone:</PrintOrderDetailsDataHeader>
                  <PrintOrderDetailsDataCell>{telephone}</PrintOrderDetailsDataCell>
                </OrderDetailsDataRow>
                <OrderDetailsDataRow>
                  <PrintOrderDetailsDataHeader>Email:</PrintOrderDetailsDataHeader>
                  <PrintOrderDetailsDataCell>{email}</PrintOrderDetailsDataCell>
                </OrderDetailsDataRow>
              </OrderDetailsData>
            </PrintOrderDetailsSection>
            <PrintOrderDetailsSection>
              <OrderDetailsPanelTitle>Delivery address</OrderDetailsPanelTitle>
              <OrderDetailsData>
                <OrderDetailsDataRow>
                  <PrintOrderDetailsDataHeader>Building name / number:</PrintOrderDetailsDataHeader>
                  <PrintOrderDetailsDataCell>{address1}</PrintOrderDetailsDataCell>
                </OrderDetailsDataRow>
                <OrderDetailsDataRow>
                  <PrintOrderDetailsDataHeader>Street address:</PrintOrderDetailsDataHeader>
                  <PrintOrderDetailsDataCell>{address2}</PrintOrderDetailsDataCell>
                </OrderDetailsDataRow>
                <OrderDetailsDataRow>
                  <PrintOrderDetailsDataHeader>Town / City:</PrintOrderDetailsDataHeader>
                  <PrintOrderDetailsDataCell>{city}</PrintOrderDetailsDataCell>
                </OrderDetailsDataRow>
                <OrderDetailsDataRow>
                  <PrintOrderDetailsDataHeader>Postcode:</PrintOrderDetailsDataHeader>
                  <PrintOrderDetailsDataCell>{postalCode}</PrintOrderDetailsDataCell>
                </OrderDetailsDataRow>
                <OrderDetailsDataRow>
                  <PrintOrderDetailsDataHeader>Country:</PrintOrderDetailsDataHeader>
                  <PrintOrderDetailsDataCell>{country}</PrintOrderDetailsDataCell>
                </OrderDetailsDataRow>
              </OrderDetailsData>
            </PrintOrderDetailsSection>
          </PrintDetailsAndAddress>
          <OrderDetailsDivider />
          <OrderDetailsSection>
            <OrderDetailsData>
              <OrderDetailsDataRow>
                <PrintOrderDetailsDataHeader>Customer ref:</PrintOrderDetailsDataHeader>
                <PrintOrderDetailsDataCell>{customerReference}</PrintOrderDetailsDataCell>
              </OrderDetailsDataRow>
              <OrderDetailsDataRow>
                <PrintOrderDetailsDataHeader>{customerAccountFieldLabel}</PrintOrderDetailsDataHeader>
                <PrintOrderDetailsDataCell>{customerAccountFieldValue}</PrintOrderDetailsDataCell>
              </OrderDetailsDataRow>
              <OrderDetailsDataRow>
                <PrintOrderDetailsDataHeader>Message from customer:</PrintOrderDetailsDataHeader>
                <PrintOrderDetailsDataCell sx={{ fontWeight: 'normal' }}>{messageToSeller}</PrintOrderDetailsDataCell>
              </OrderDetailsDataRow>
            </OrderDetailsData>
          </OrderDetailsSection>
        </PrintOrderDetailsLeftPanel>
        <PrintOrderDetailsPanel>
          <OrderDetailsSection sx={{ height: '100%' }}>
            <OrderDetailsPanelTitle>Parts ordered</OrderDetailsPanelTitle>
            {items.length !== 0 ? <ReceivedOrderPartsList items={items} totalPrice={totalPrice} /> : null}
          </OrderDetailsSection>
        </PrintOrderDetailsPanel>
      </PrintOrderDetailsMainContent>
    </OrderDetailsPrintContainer>
  );
}

const OrderDetailsPrintContainer = styled('div')`
  p {
    text-align: left;
  }
`;

const PrintDetailsAndAddress = styled('div')`
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  margin-bottom: 2rem;
`;

const PrintOrderDetailsSection = styled(MUIBox)`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 2rem;
  :first-child {
    border-right: 1px solid ${({ theme }) => theme.palette.grey[300]};
  }
`;

const PrintOrderDetailsMainContent = styled(OrderDetailsMainContent)`
  flex-direction: column;
`;

const PrintOrderDetailsLeftPanel = styled(OrderDetailsLeftPanel)`
  width: 93%;
  margin: 2rem auto 2rem auto;
`;

const PrintOrderDetailsPanel = styled(OrderDetailsPanel)`
  width: 93%;
  margin: 0 auto 2rem auto;
`;

const PrintOrderDetailsDataHeader = styled(OrderDetailsDataHeader)`
  width: 30%;
`;

const PrintOrderDetailsDataCell = styled(OrderDetailsDataCell)`
  width: 70%;
`;
