import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { FullscreenLoadingSpinner } from 'src/components/Loading/FullscreenLoadingSpinner';
import { Toast } from 'src/components/Toast';
import { ShoppingCartPanelContext } from 'src/providers/ShoppingCart';
import useShoppingCartMutation from 'src/providers/ShoppingCart/useShoppingCartMutation';
import useShoppingCartQuery from 'src/providers/ShoppingCart/useShoppingCartQuery';
import { useUser } from 'src/providers/User';
import { postOrder } from 'src/services/api';
import isPOR, { calculateTotalWithPOR } from 'src/services/isPOR';
import { MainTemplate } from 'src/templates/MainTemplate';
import { determineCurrencyToUse } from 'src/services/formatCurrency';
import { useTenant } from 'src/providers/Tenant';
import Checkout from './Checkout';
import Confirmation, { GTMData } from './Confirmation';
import { Background } from './styles';

export default function CheckoutPage() {
  const { path } = useRouteMatch();
  const { preferredCurrency, currencies } = useTenant();
  const { discountPercentage, userDetails } = useUser();

  const currencyToUse = determineCurrencyToUse({ currency: userDetails.currency, preferredCurrency, currencies });

  const { setPanelOpen } = useContext(ShoppingCartPanelContext);
  const { data: cart, isFetching } = useShoppingCartQuery();
  const { mutate: mutateCart } = useShoppingCartMutation();
  const cartTotal = calculateTotalWithPOR(cart);

  const [isOrderPOR, setIsOrderPOR] = useState(false);
  const { push, goBack } = useHistory();

  const {
    data: response,
    mutateAsync: sendOrder,
    isError,
    isLoading,
  } = useMutation(postOrder, {
    onSuccess: () => mutateCart([]),
  });

  const orderNumber = response && response.data.orderNumber;

  const queryClient = useQueryClient();

  useEffect(() => {
    setIsOrderPOR(isPOR(cart)); // we are doing this because we need to preserve the isCartPOR boolean before the cart is cleared, otherwise the cart is never POR when emptied
    if (cart) queryClient.refetchQueries(['shoppingCart']); // make sure content is up to date for the checkout
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Prevent entering checkout page if the cart is empty
  useEffect(() => {
    if (cart.length === 0 && !orderNumber) {
      push('/product-library'); // On mobile shopping cart panel is also accessible from product-library page
      setPanelOpen(true);
    }
  }, [cart.length, push, orderNumber, setPanelOpen]);

  const gtmData: GTMData = {
    items: cart,
    value: cartTotal,
    isOrderPOR,
    currency: preferredCurrency,
    transactionId: orderNumber,
  };

  return (
    <MainTemplate>
      <Background>
        {isLoading ? (
          <FullscreenLoadingSpinner />
        ) : (
          <Switch>
            <Route exact path={`${path}/shipping-info`}>
              <Checkout
                cart={cart}
                cartTotal={cartTotal}
                orderNumber={orderNumber}
                currency={currencyToUse}
                sendOrder={sendOrder}
                isOrderPOR={isOrderPOR}
                discountPercentage={discountPercentage}
                currentPath={path}
                isFetching={isFetching}
                goBack={goBack}
                defaultAccountDetails={userDetails}
              />
            </Route>
            <Route exact path={`${path}/thank-you`}>
              <Confirmation confirmationNo={orderNumber} isOrderPOR={isOrderPOR} gtmData={gtmData} />
            </Route>
          </Switch>
        )}
      </Background>
      <Toast
        dependency={isError}
        severity="error"
        title="Error"
        message="There has been an error submitting your order!"
      />
    </MainTemplate>
  );
}
