import { BackButton } from 'src/components/Button';
import { AccountDetailsProps } from '../AccountDetailsPage';
import { InnerContentContainer } from '../Desktop/styles';
import MobileChangeYourPassword from './ChangeYourPassword/MobileChangeYourPassword';
import MobileMyProfile from './MyProfile/MobileMyProfile';
import { MobileContentContainer } from './styles';

export default function MobileAccountDetails({
  oldPassword,
  setOldPassword,
  newPassword,
  setNewPassword,
  verifyNewPassword,
  setVerifyNewPassword,
  submit,
  checkPasswordsMatch,
  error,
  userDetailsFormState,
  handleMyProfileFormChange,
  submitMyProfileForm,
  userEmail,
  isApplyUserLoading,
  onClickBackButton,
}: AccountDetailsProps) {
  return (
    <MobileContentContainer>
      <BackButton onClick={onClickBackButton} />
      <InnerContentContainer>
        <MobileMyProfile
          userDetailsFormState={userDetailsFormState}
          handleMyProfileFormChange={handleMyProfileFormChange}
          submitMyProfileForm={submitMyProfileForm}
          userEmail={userEmail}
          isApplyUserLoading={isApplyUserLoading}
        />
        <MobileChangeYourPassword
          oldPassword={oldPassword}
          setOldPassword={setOldPassword}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          verifyNewPassword={verifyNewPassword}
          setVerifyNewPassword={setVerifyNewPassword}
          submit={submit}
          checkPasswordsMatch={checkPasswordsMatch}
          error={error}
        />
      </InnerContentContainer>
    </MobileContentContainer>
  );
}
