import { faListCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';

import MUITypograhy from '@mui/material/Typography';

const ReceivedOrdersHeader = styled(MUITypograhy)`
  color: ${({ theme }) => theme.palette.grey[900]};
  margin-bottom: 2rem;
`;

ReceivedOrdersHeader.defaultProps = { variant: 'h5' };

const ReceivedOrdersBodyContainer = styled('div')`
  display: flex;
  margin-top: 1rem;
  gap: 2rem;
`;

const ReceivedOrdersBody = styled(MUITypograhy)`
  width: 50%;
`;

export default function ReceivedOrdersTableDescription() {
  return (
    <ReceivedOrdersHeader>
      <FontAwesomeIcon icon={faListCheck} size="lg" /> Received Orders
      <ReceivedOrdersBodyContainer>
        <ReceivedOrdersBody>
          You can view the orders submitted by all the dealerships in the below table. Click on a confirmation number to
          view the order details.
        </ReceivedOrdersBody>
        <ReceivedOrdersBody>
          Once the order has been delivered to the dealership you can click on the “Mark as completed” button.
        </ReceivedOrdersBody>
      </ReceivedOrdersBodyContainer>
    </ReceivedOrdersHeader>
  );
}
