import type { PartNode } from '@samsonvt/shared-types/productLambda';
import { ModelType } from '@samsonvt/shared-types/productsTable';
import { uniqBy } from 'lodash-es';
import { createContext, useContext, useMemo } from 'react';
import { useProduct } from 'src/providers/Product';
import { useProductsListQuery } from 'src/providers/useProductsListQuery';
import { Vector3Tuple } from 'three';
import { usePart } from './part';
import { makePartUrl } from './partUrl';
import { findSplitParent } from './partUtils';
import { transformChildPart, transformParentPart } from './transformParts';

export interface NamedLink {
  name: string;
  url: string;
  data?: PartNode;
  id?: string;
}

export interface Hotspot extends NamedLink {
  position: Vector3Tuple;
  modelType?: ModelType;
}

export class PartNavigation {
  hotspots: Hotspot[];
  children: NamedLink[];
  parents: NamedLink[];
  relatedParts: NamedLink[];

  constructor(
    hotspots: Hotspot[] = [],
    children: NamedLink[] = [],
    parents: NamedLink[] = [],
    relatedParts: NamedLink[] = []
  ) {
    this.hotspots = hotspots;
    this.children = children;
    this.parents = parents;
    this.relatedParts = relatedParts;
  }
}

export const PartNavigationContext = createContext(new PartNavigation());
// Need to use an alternative method of creation for storybook
export const StoryBookPartNavigationContext = createContext({} as PartNavigation);

export function PartNavigationProvider({ children }: any) {
  const { productId, parentProductId, modelType, getPartsByPartNumber } = useProduct();
  const { partPath: selectedPart } = usePart();
  const { data: products } = useProductsListQuery();

  const hotspots: Hotspot[] = useMemo(() => {
    // For future refactor: Consider moving this logic to <FocalPointRenderer> (The principle of subsidiarity)
    const childParts = selectedPart?.part.children ?? [];
    return childParts
      .filter((part) => part.focal)
      .map((part) => ({
        name: part.name,
        // .labelPosition is used for positioning of the hotspots as off SVT-1981 / PHONG-408. .target is a fallback.
        position: part.focal!.labelPosition ?? part.focal!.target,
        url: makePartUrl(productId, part.id, { modelType }),
        modelType,
      }));
  }, [productId, selectedPart?.part.children, modelType]);

  const childPartLinks: NamedLink[] = useMemo(() => {
    const childParts = selectedPart?.part.children ?? [];
    const allChildPartsLinks = uniqBy(
      childParts.map((part) => transformChildPart(part, productId, selectedPart, modelType)).flat(Infinity),
      'url'
    ) as NamedLink[];

    return allChildPartsLinks;
  }, [productId, selectedPart, modelType]);

  const parentPartLinks: NamedLink[] = useMemo(() => {
    let parentLinks: NamedLink[] = (selectedPart?.parents ?? []).map((part) =>
      transformParentPart(part, productId, modelType)
    );
  
    parentLinks = findSplitParent(parentLinks, modelType, parentProductId, products);

    return parentLinks;
  }, [productId, selectedPart?.parents, modelType, parentProductId, products]);

  const relatedParts = useMemo<NamedLink[]>(() => {
    if (!selectedPart) {
      return [];
    }

    const matchingParts = selectedPart?.part.name ? getPartsByPartNumber(selectedPart.part.partNumber) : [];
    const outsideOfCurrentPath = matchingParts.filter((path) => path.pathHash !== selectedPart.pathHash);

    return uniqBy(outsideOfCurrentPath, 'pathHash').map(({ part, pathHash, group }) => ({
      name: group?.name ?? part.name,
      url: makePartUrl(productId, part.id, { group: pathHash, modelType }),
    }));
  }, [selectedPart, productId, modelType, getPartsByPartNumber]);

  const values = useMemo(
    () => new PartNavigation(hotspots, childPartLinks, parentPartLinks, relatedParts),
    [hotspots, childPartLinks, parentPartLinks, relatedParts]
  );

  return <PartNavigationContext.Provider value={values}> {children} </PartNavigationContext.Provider>;
}

export const usePartNavigation = (): PartNavigation => {
  const context = useContext(PartNavigationContext);

  if (context === undefined) {
    throw new Error('`usePartNavigation` hook must be used within a `PartNavigationProvider` component');
  }

  return context;
};
