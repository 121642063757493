import type { PartNode } from '@samsonvt/shared-types/productLambda';
import { useEffect } from 'react';
import { usePart } from 'src/services/part';
import { makePartUrl } from 'src/services/partUrl';
import { useProduct } from 'src/providers/Product';
import AlternativeOptionCard from './AlternativeOptionCard';
import { PaddedBlock } from '../../styles';

interface AlternativeOptionsProps {
  current: PartNode;
  alternativeOptions: PartNode[];
}

export default function AlternativeOptionsTab({ current, alternativeOptions }: AlternativeOptionsProps) {
  const { partPath } = usePart();
  const { productId, toggleVariantNames, hiddenVariantNames, modelType } = useProduct();

  const currentObject3DNameAsArray = current.object3DName ? [current.object3DName] : [];

  useEffect(() => {
    // Show selected variant on first render when current part is set from url
    // This is done here and not in provider due to nesting of providers, neither current nor variant group are easily accessible in providers.
    if (current.object3DName && hiddenVariantNames?.includes(current.object3DName)) {
      toggleVariantNames(
        alternativeOptions.map((option) => option.object3DName || 'missing-variant-object-3D-name'),
        current.object3DName
      );
    }
  });

  if (!alternativeOptions.length) {
    return <PaddedBlock>This part does not have any alternative options</PaddedBlock>;
  }

  return (
    <PaddedBlock>
      <h3>Alternate Parts</h3>
      <ul>
        {alternativeOptions.map((item: PartNode) => {
          // glbIds would be only missing or empty for nodes in the hierarchy which are not actually parts, for example hotspot nodes or container nodes.
          const selectedId = item.object3DName as string;

          const url = makePartUrl(productId, item.id, { group: partPath?.pathHash, mesh: selectedId, modelType });

          return (
            <AlternativeOptionCard
              key={item.id}
              item={item}
              onClick={() => toggleVariantNames(currentObject3DNameAsArray, selectedId)}
              link={url}
            />
          );
        })}
      </ul>
    </PaddedBlock>
  );
}
