import { AnimationClip, LoadingManager, Object3D } from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { fromArrayBuffer } from './blobConverter';
import { error as logError, info as logInfo } from './log';

interface TypedLoader {
  loadAsync(url: string, onProgress?: (event: ProgressEvent) => void): Promise<LoaderResult>;
}
class SvtLoader implements TypedLoader {
  private gltfLoader: GLTFLoader;
  private decompressor: DRACOLoader;

  constructor(context: string) {
    const loadingManager = new LoadingManager(
      undefined,
      (url) => logInfo(`LoadingManager:${context}:onProgress`, url),
      (url) => logError(new Error('loader error'), `LoadingManager:${context}:onError ${url}`)
    );

    const dracoLoader = new DRACOLoader(loadingManager);
    dracoLoader.setDecoderPath('/draco/');
    dracoLoader.setDecoderConfig({ type: 'js' });

    const GTLFLoader = new GLTFLoader(loadingManager);
    GTLFLoader.setDRACOLoader(dracoLoader);

    this.gltfLoader = GTLFLoader;
    this.decompressor = dracoLoader;
  }

  async loadAsync(url: string, onProgress?: (event: ProgressEvent<EventTarget>) => void): Promise<LoaderResult> {
    const loaderResult = await this.gltfLoader.loadAsync(url, onProgress);
    this.decompressor.dispose(); // clear Dedicated Workers for decompression
    return loaderResult;
  }
}
interface LoaderResult {
  animations?: AnimationClip[];
  scene: Object3D;
}

interface LoadingProps {
  blob: ArrayBuffer;
  cancelled: () => boolean;
}

export const loadModel = async ({ blob, cancelled }: LoadingProps) => {
  const url = URL.createObjectURL(fromArrayBuffer(blob));
  const loader = new SvtLoader('model');
  const object = await loader.loadAsync(url);

  if (!cancelled()) {
    return object;
  }
};
