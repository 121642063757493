import { styled } from '@mui/material';
import MUIFormControl from '@mui/material/FormControl';
import MUIInputLabel from '@mui/material/InputLabel';
import MUISelect from '@mui/material/Select';

interface SelectFieldProps {
  handleChange: (url: string) => void;
  placeholder: string;
  value: string;
  children: React.ReactNode;
}

export default function SelectField({ handleChange, value, placeholder, ...props }: SelectFieldProps) {
  return (
    <StyledMUIFormControl size="small" variant="outlined">
      {placeholder && <StyledMUILabel>{placeholder}</StyledMUILabel>}
      <StyledMUISelect
        label={placeholder} // placeholder used twice due to https://github.com/mui/material-ui/issues/27533
        value={value}
        name="tenant"
        onChange={(event) => handleChange(event.target.value as string)}
        {...props}
      />
    </StyledMUIFormControl>
  );
}

const StyledMUIFormControl = styled(MUIFormControl)`
  width: 200px;
  label {
    color: ${({ theme }) => theme.palette.brand.contrastText};
  }
`;

const StyledMUISelect = styled(MUISelect)`
  color: ${({ theme }) => theme.palette.brand.contrastText} !important;
  fieldset {
    border-color: ${({ theme }) => theme.palette.primary.light} !important;
  }
  svg {
    background-color: ${({ theme }) => theme.palette.primary.light};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

const StyledMUILabel = styled(MUIInputLabel)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  &.Mui-focused {
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;
