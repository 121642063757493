import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import MUIButton from '@mui/material/Button';
import MUITypography from '@mui/material/Typography';
import type { Order } from '@samsonvt/shared-types/orderLambda';
import { useEffect, useState } from 'react';
import { Margin, usePDF } from 'react-to-pdf';
import { ExportPdfOrderDetails } from './ReceivedOrdersPage/ReceivedOrdersTable/ExportPdfOrderDetails';

interface ExportPdfButtonProps extends React.HTMLProps<HTMLButtonElement> {
  order: Order;
}

export function ExportPdfButton({ order }: ExportPdfButtonProps) {
  const page = {
    margin: Margin.SMALL,
  };

  const filename = order.eCommerceOrderName || order.orderId;
  const { toPDF, targetRef } = usePDF({ filename, page });
  const [displayOrder, setDisplayOrder] = useState(false);

  const exportPdf = () => {
    setDisplayOrder(true);
  };

  // Display it conditionally only when needed for print to avoid polluting the DOM
  useEffect(() => {
    if (displayOrder) {
      toPDF();
      setDisplayOrder(false);
    }
  }, [displayOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  const orderMarkup = displayOrder ? (
    <OrderPrintPage ref={targetRef}>
      <ExportPdfOrderDetails order={order} />
    </OrderPrintPage>
  ) : null;

  return (
    <ExportButton onClick={exportPdf} data-testid="download-pdf-button">
      <DownloadIcon icon={faFilePdf} />
      <MUITypography fontWeight="bold">Export order</MUITypography>
      {orderMarkup}
    </ExportButton>
  );
}
const OrderPrintPage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Display it outside viewport */
  position: absolute;
  left: -9999px;
  top: -9999px;
`;

const DownloadIcon = styled(FontAwesomeIcon)`
  margin-right: 0.3rem;
`;

const ExportButton = styled(MUIButton)`
  text-transform: none;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.grey[900]};
  background-color: ${({ theme }) => theme.palette.common.white};

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }
`;

ExportButton.defaultProps = { variant: 'outlined' };
