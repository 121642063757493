import { v4 as uuidv4 } from 'uuid';

import {
  EditPartDetailsRequestBody,
  NewPartDetailsRequestBody,
  PartDetails,
  PartTypes,
  SupersessionV2,
} from '@samsonvt/shared-types/partService';
import { ISO4217 } from '@samsonvt/shared-types/orderLambda';
import { PartPriceV2 } from '@samsonvt/shared-types/partsTable';
import { PartDetailsForm } from './EditModal';

export const createDefaultCustomAttributes = (customAttributesNames?: string[]) => {
  if (customAttributesNames) {
    const defaultAttributes = {} as Record<string, string>;
    customAttributesNames.forEach((key) => {
      defaultAttributes[key] = '';
    });

    return defaultAttributes;
  }

  return undefined;
};

const determinePriceV2 = (
  partDetails: Omit<PartDetails, 'svtPartID' | 'previousPartNumbers'> & {
    svtPartID?: string; // Allow using this function to create extracted details
    previousPartNumbers?: SupersessionV2[];
  },
  allowedCurrencies: ISO4217[]
) => {
  const originalPriceV2 = partDetails.shopping?.priceV2;
  const result: PartPriceV2[] = [];

  if (!originalPriceV2) {
    return allowedCurrencies.map((currency) => ({
      currency,
      amount: undefined,
    }));
  }

  allowedCurrencies.forEach((allowedCurrency) => {
    const alreadyExistingCurrencyPrice = originalPriceV2.find((price) => price.currency === allowedCurrency);
    result.push(alreadyExistingCurrencyPrice || { amount: undefined, currency: allowedCurrency });
  });

  return result;
};

export const createEmptyNewPartForm = (currencies: ISO4217[]) => {
  const emptyNewPartForm: Omit<PartDetailsForm, 'partNumber'> = {
    displayName: '',
    price: undefined,
    isPor: false,
    availableToOrder: false,
    priceV2: currencies?.map((currency) => ({ currency, amount: undefined })),
    supersessions: [],
  };

  return emptyNewPartForm;
};

export const partDetailsToPartForm = (
  partDetails: Omit<PartDetails, 'svtPartID' | 'previousPartNumbers'> & {
    svtPartID?: string; // Allow using this function to create extracted details
    previousPartNumbers?: SupersessionV2[];
  },
  allowedCurrencies: ISO4217[]
): PartDetailsForm => ({
  svtPartID: partDetails.svtPartID,
  partNumber: partDetails.part.number,
  displayName: partDetails.part.name,
  price: partDetails.shopping?.price,
  priceV2: determinePriceV2(partDetails, allowedCurrencies),
  isPor: partDetails.shopping?.priceOnRequest || false, // use shopping POR do not infer from price because when partDetails.shopping?.priceOnRequest === true price is undefined in the DB
  availableToOrder: Boolean(partDetails.shopping?.availability),
  supersessions:
    partDetails.previousPartNumbers?.map((previousPartNumber) =>
      // UI will need temporary IDs to manage supersession list
      ({ ...previousPartNumber, tempID: uuidv4() })
    ) || [],
  customAttributes: partDetails.part.customAttributes,
  description: partDetails.part.description || '', // Prevent null
});

export const formToPartDetails = (
  form: PartDetailsForm,
  originalPartDetails: PartDetails | undefined
): NewPartDetailsRequestBody | EditPartDetailsRequestBody => {
  const { isPor, availableToOrder, price, displayName, partNumber, svtPartID, customAttributes, description, priceV2 } =
    form;
  const priceAsNumber = isPor ? undefined : (price as number | undefined); // Probably this should be typed as number also in PartDetailsForm
  const requestBody: NewPartDetailsRequestBody = {
    part: {
      name: displayName || '',
      number: partNumber,
      subType: 'Part' as PartTypes,
      customAttributes,
      description,
    },
    shopping: {
      price: priceAsNumber,
      priceOnRequest: isPor,
      availability: availableToOrder,
      priceV2: priceV2
        ?.map((price) => ({ ...price, amount: Number(price.amount) }))
        .filter((price) => price.amount && price.amount > 0),
    },
    previousPartNumbers: [],
  };
  if (svtPartID) {
    (requestBody as EditPartDetailsRequestBody).svtPartID = svtPartID;
  }

  if (originalPartDetails?.shopping?.eCommerceID) {
    requestBody.shopping = {
      ...requestBody.shopping!,
      eCommerceID: originalPartDetails.shopping.eCommerceID,
    };
  }

  if (form.supersessions) {
    (requestBody as EditPartDetailsRequestBody).previousPartNumbers = form.supersessions.map(({ part, svtPartID }) => ({
      part: {
        // Assign constants to new supersession and dissasociate tempID
        number: part.number || '',
        notes: part.notes || '',
        name: part.name || 'supersession-stub-name',
        subType: part.subType || ('Supersession' as PartTypes),
      },
      svtPartID,
    }));
  }

  return requestBody;
};

export const isNew = (
  partDetails: NewPartDetailsRequestBody | EditPartDetailsRequestBody
): partDetails is NewPartDetailsRequestBody => !('svtPartID' in partDetails) || !partDetails.svtPartID;
