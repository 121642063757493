import { useTheme } from '@mui/material';

export function LoadingSpinner({
  width = '200px',
  height = '200px',
  viewBox = '0 0 100 100',
  ...props
}: {
  width?: string;
  height?: string;
  viewBox?: string;
  [x: string]: any;
}) {
  const {
    palette: {
      primary: { main: primaryMain },
      secondary: { main: secondaryMain },
    },
  } = useTheme();
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      preserveAspectRatio="xMidYMid"
      style={{ background: 'none' }}
      {...props}
    >
      <circle
        stroke={primaryMain}
        id="outer"
        cx="50"
        cy="50"
        fill="none"
        strokeLinecap="round"
        r="10"
        strokeWidth="2"
        strokeDasharray="15.707963267948966 15.707963267948966"
        transform="rotate(226.571 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="1.8s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
      <circle
        stroke={secondaryMain}
        id="inner"
        cx="50"
        cy="50"
        fill="none"
        strokeLinecap="round"
        r="7"
        strokeWidth="2"
        strokeDasharray="10.995574287564276 10.995574287564276"
        strokeDashoffset="10.995574287564276"
        transform="rotate(-226.571 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;-360 50 50"
          keyTimes="0;1"
          dur="1.8s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
}
