import { CheckoutStepper } from 'src/components/Stepper';
import { BackButton } from 'src/components/Button';
import { DesktopCheckoutCart } from './DesktopCheckoutCart';
import { DesktopCheckoutForm } from './DesktopCheckoutForm';
import { DesktopCheckoutFormContainer, DesktopCheckoutPageContainer } from '../styles';
import type { CheckoutProps } from '../Checkout';

export default function DesktopCheckout({
  cart,
  orderNumber,
  isOrderPOR,
  discountPercentage,
  currency,
  sendOrder,
  cartTotal,
  isFetching,
  goBack,
  defaultAccountDetails,
}: CheckoutProps) {
  return (
    <DesktopCheckoutPageContainer>
      <BackButton onClick={goBack} />
      <CheckoutStepper currentStep={!orderNumber ? 1 : 3} />
      <DesktopCheckoutFormContainer>
        <>
          <DesktopCheckoutForm
            cart={cart}
            isOrderPOR={isOrderPOR}
            discountPercentage={discountPercentage}
            currency={currency}
            sendOrder={sendOrder}
            isFetching={isFetching}
            defaultAccountDetails={defaultAccountDetails}
          />
          <DesktopCheckoutCart cart={cart} cartTotal={cartTotal} isFetching={isFetching} />
        </>
      </DesktopCheckoutFormContainer>
    </DesktopCheckoutPageContainer>
  );
}
