import MUITable from '@mui/material/Table';
import MUITableBody from '@mui/material/TableBody';
import MUITableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import type { Order } from '@samsonvt/shared-types/orderLambda';
import { TableHeader } from 'src/components/Table/MUITableStyles';
import { Toast } from 'src/components/Toast';
import { SortOption, sortOptions } from 'src/pages/OrdersPages/filters';
import { getSortIcon } from 'src/pages/OrdersPages/ordersUtils';
import { useSortedOrders } from 'src/pages/OrdersPages/useSortedOrders';
import { EmptyTable, TableStyles } from '../../../styles';
import { TableFooter } from '../../../TableFooter';
import { MobileMyOrdersTableHeader } from './MobileMyOrdersTableHeader';
import MobileMyOrdersTableRow from './MobileMyOrdersTableRow';

export function MobileMyOrdersTable() {
  const {
    displayOrders,
    isLoadingOrders,
    isErrorLoadingOrders,
    isTheTableEmpty,
    searchPhrase,
    setSearchPhrase,
    setSorting,
    currentSortOption,
  } = useSortedOrders();

  const getHandleSort = (sortOption: SortOption) => () => setSorting(sortOption);
  const { CONFIRMATION_NUMBER, DATE } = sortOptions;
  return (
    <>
      <MobileMyOrdersTableHeader searchPhrase={searchPhrase} setSearchPhrase={setSearchPhrase} orders={displayOrders} />
      <TableStyles>
        <MUITable>
          <colgroup>
            <col style={{ width: '60%' }} />
            <col style={{ width: '40%' }} />
          </colgroup>
          <MUITableHead>
            <MUITableRow>
              <TableHeader onClick={getHandleSort(CONFIRMATION_NUMBER)}>
                Confirmation number {getSortIcon({ column: CONFIRMATION_NUMBER, currentSortOption })}
              </TableHeader>
              <TableHeader onClick={getHandleSort(DATE)}>
                Date {getSortIcon({ column: DATE, currentSortOption })}
              </TableHeader>
            </MUITableRow>
          </MUITableHead>
          <MUITableBody>
            {displayOrders.map((order: Order) => (
              <MobileMyOrdersTableRow order={order} key={order.orderId} />
            ))}
          </MUITableBody>
        </MUITable>
        <TableFooter
          areTheOrdersAllLoaded={displayOrders.length > 0}
          isLoading={isLoadingOrders}
          filteredOrdersLength={displayOrders.length}
        />
        {isTheTableEmpty && (
          <EmptyTable data-testid="empty-table">
            {isErrorLoadingOrders ? 'There has been an error while loading your orders' : 'No orders found'}
          </EmptyTable>
        )}
      </TableStyles>
      <Toast
        dependency={isErrorLoadingOrders}
        severity="error"
        title="Error"
        message="There has been an error while loading your orders"
      />
    </>
  );
}
