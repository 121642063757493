import type { FeatureFlag } from '@samsonvt/shared-types/accountsTable';
import { useTenant } from "./providers/Tenant";

interface IFeatureProps {
  children: JSX.Element | null;
  flag: FeatureFlag;
  alternate?: JSX.Element;
}

export const isFeatureSupported = (flag: FeatureFlag, enabledFeatures: FeatureFlag[]) => enabledFeatures.includes(flag);

export function Feature({ children, flag, alternate }: IFeatureProps) {
  const { enabledFeatures } = useTenant();

  if (!isFeatureSupported(flag, enabledFeatures)) {
    return alternate || null;
  }

  return children;
}
