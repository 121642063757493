import { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import Stack from '@mui/material/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { workInstructionsLink } from '../../../../../../utils/urls';
import { isWithinIframe } from '../../../../../../services/checkForIframe';
import { IRepairStepView, IRepairView } from '../../../../../../providers/repairs/types';
import { RepairDetailContext } from '../../../../../../providers/repairs/RepairDetails';
import { ProductParams } from './index';
import { StepButton } from './StepButton';

export function StepButtons() {
  const { productId, section } = useParams<ProductParams>();
  const repairDetails = useContext(RepairDetailContext);
  const { search: tagUrlSection } = useLocation();

  if (!repairDetails) {
    return null;
  }

  const { step, repair } = repairDetails;
  const { nextStepUrl, previousStepUrl } = stepUrls(repair, step);
  const repairsHome = workInstructionsLink(productId);
  const currentRepairStart = workInstructionsLink(productId, section);

  const nextStepURL = `${
    isWithinIframe ? nextStepUrl || currentRepairStart : nextStepUrl || repairsHome
  }${tagUrlSection}`;

  const previousStepURL = `${
    isWithinIframe ? previousStepUrl || currentRepairStart : previousStepUrl || repairsHome
  }${tagUrlSection}`;

  return (
    // Flex layout
    <Stack
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      sx={{
        gap: '1rem',
        padding: '0 1rem 1rem',
      }}
    >
      {/* Previous Step Button */}
      <StepButton
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        to={previousStepURL}
        data-testid="previous-step-button"
        disabled={previousStepUrl === undefined}>
        Previous Step
      </StepButton>

      {/* Next Step Button */}
      <StepButton
        endIcon={<FontAwesomeIcon icon={faChevronRight} />}
        to={nextStepURL}
        data-testid="next-step-button">
        {nextStepUrl ? 'Next Step' : 'Complete'}
      </StepButton>
    </Stack>
  );
}

const stepUrls = (repairDetails: IRepairView, step: IRepairStepView) => {
  const flattenedSteps = repairDetails.sections.map((section) => section.steps).flat();
  const currentStepIndex = flattenedSteps.findIndex((s) => s.url === step.url);
  const nextStep = flattenedSteps[currentStepIndex + 1];
  const previousStep = flattenedSteps[currentStepIndex - 1];
  return {
    nextStepUrl: nextStep ? nextStep.url : undefined,
    previousStepUrl: previousStep ? previousStep.url : undefined,
  };
};
