import {
  faCartShopping,
  faGridHorizontal,
  faListCheck,
  faCartCircleCheck,
  faCircleUser
} from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, css, styled } from '@mui/material';
import Tab, { TabProps } from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Link, useLocation, LinkProps } from 'react-router-dom';
import samsonLogoUrl from 'src/assets/logo-default.svg';
import { FlexContainer } from 'src/components/FlexContainer';
import { isFeatureSupported } from 'src/Feature';
import { usePermissions } from 'src/hooks/usePermissions';
import useShoppingCartQuery from 'src/providers/ShoppingCart/useShoppingCartQuery';
import { useTenant } from 'src/providers/Tenant';
import { useUser } from 'src/providers/User';
import { PAGES } from '..';
import { LogoLinkElement, StyledClientLogo } from '../styles';
import { TenantSwitcher } from '../TenantSwitcher';
import { CurrencySwitcher } from '../CurrencySwitcher/CurrencySwitcher';

export function DesktopMenu() {
  const {
    logoUrl,
    userDetails: { name }
  } = useUser();
  const { enabledFeatures } = useTenant();

  const hasPermissionsToOperateOnAllOfTenantsOrders = usePermissions(
    ['list', 'order.history'],
    ['view', 'order.history'],
    ['update', 'order.history']
  );

  const { data: cart } = useShoppingCartQuery();
  const hasPermissionsToViewOwnsOrders = usePermissions(['list', 'order'], ['view', 'order'], ['email', 'order']);

  const itemsInCart = cart.reduce((total, { quantity }) => total + quantity, 0);

  const { pathname } = useLocation();
  const TABS_PATHS = Object.values(PAGES).map(({ path }) => path);
  const activeTabIndex = TABS_PATHS.findIndex((tab) => pathname.startsWith(tab));
  const activeTab = activeTabIndex === -1 ? '/product-library' : TABS_PATHS[activeTabIndex];

  const displayReceivedOrders =
    hasPermissionsToOperateOnAllOfTenantsOrders && isFeatureSupported('shopping-cart', enabledFeatures);
  const displayMyOrders =
    !hasPermissionsToOperateOnAllOfTenantsOrders &&
    hasPermissionsToViewOwnsOrders &&
    isFeatureSupported('shopping-cart', enabledFeatures);

  const displayShoppingCart = isFeatureSupported('shopping-cart', enabledFeatures);

  const tabItemLine = {
    ':after': {
      content: "''",
      position: 'absolute',
      right: '0',
      width: '1px',
      height: '27px',
      backgroundColor: '#616161'
    }
  };

  return (
    <DesktopMenuContainer>
      <LogoLinkElement to="/" data-testid="tenant-logo">
        <StyledClientLogo alt={name} src={logoUrl ?? samsonLogoUrl} />
      </LogoLinkElement>
      <FlexContainer sx={{ alignItems: 'center', mx: 2 }}>
        <TenantSwitcher />
      </FlexContainer>
      <StyledTabs
        value={activeTab}
        TabIndicatorProps={{
          sx: { background: (theme) => theme.palette.secondary.main }
        }}
      >
        <StyledTab
          sx={{ mr: 'auto' }}
          icon={<FontAwesomeIcon icon={faGridHorizontal} className="fa-xl" />}
          label={PAGES.library.label}
          value={PAGES.library.path}
          to={PAGES.library.path}
          component={Link}
        />

        <StyledTab
          sx={tabItemLine}
          component={CurrencySwitcher}
          // Tab requires a path but we are not wanting to move to another page so leave it empty
          to=""
        />

        {displayReceivedOrders ? (
          <StyledTab
            sx={tabItemLine}
            icon={<FontAwesomeIcon icon={faListCheck} className="fa-xl" />}
            value={PAGES.received_orders.path}
            label={PAGES.received_orders.label}
            to={PAGES.received_orders.path}
            component={Link}
          />
        ) : null}

        {displayMyOrders ? (
          <StyledTab
            sx={tabItemLine}
            icon={<FontAwesomeIcon icon={faCartCircleCheck} className="fa-xl" />}
            value={PAGES.my_orders.path}
            label={PAGES.my_orders.label}
            to={PAGES.my_orders.path}
            component={Link}
          />
        ) : null}

        {displayShoppingCart ? (
          <StyledTab
            sx={tabItemLine}
            icon={<FontAwesomeIcon icon={faCartShopping} className="fa-xl" />}
            value={PAGES.shopping_cart.path}
            label={
              <div>
                {PAGES.shopping_cart.label} <sup>({itemsInCart})</sup>
              </div>
            }
            to={PAGES.shopping_cart.path}
            component={Link}
            data-testid="nav-shopping-cart"
          />
        ) : null}

        <StyledTab
          icon={<FontAwesomeIcon icon={faCircleUser} className="fa-xl" />}
          value={PAGES.my_account.path}
          label={PAGES.my_account.label}
          to={PAGES.my_account.path}
          component={Link}
          data-testid="my-account-link"
        />
      </StyledTabs>
    </DesktopMenuContainer>
  );
}

const StyledTabs = styled(Tabs)`
  ${({ theme }) => css`
    .MuiTab-root,
    .MuiTab-root.Mui-selected,
    .MuiTypography-root .MuiTypography-button {
      color: ${theme.palette.brand.contrastText};
      text-transform: capitalize;
      font-size: 0.875rem;
    }
    width: 100%;
    margin-right: ${theme.spacing(2)}px;
    .MuiTab-iconWrapper {
      font-size: 1.8rem;
    }
  `}
`;

const StyledTab = styled(Tab)<TabProps & LinkProps>`
  min-height: 4.5rem; // SVT-2029 overriding material's 72px with rem's to enable scaling.
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.25s ease-in-out;
  &:hover {
    transition: border-bottom 0.25s ease-in-out;
    border-bottom: 2px solid ${({ theme }) => theme.palette.secondary.main};
  }
`;

const DesktopMenuContainer = styled(Box)`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
