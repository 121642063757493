import { faArrowCircleDown, faArrowCircleUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { CurrentSortOption, SortOption } from './filters';

type GetSortIconParams = {
  column: SortOption;
  currentSortOption: CurrentSortOption;
};

export const getSortIcon = ({ column, currentSortOption }: GetSortIconParams) => {
  if (column !== currentSortOption.sortBy) {
    return null;
  }
  return currentSortOption.sortDirection === 'asc' ? (
    <StyledInfoIcon icon={faArrowCircleDown} size="lg" />
  ) : (
    <StyledInfoIcon icon={faArrowCircleUp} size="lg" />
  );
};

export const StyledInfoIcon = styled(FontAwesomeIcon)`
  margin-left: 0.3rem;
`;
