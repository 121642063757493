import { Button, Menu, Radio, styled, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CurrencyButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  display: flex;
  padding: 0.5625rem 1rem;
  flex-direction: column;
  margin-bottom: 2px;
  font-size: 1rem;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.brand.contrastText};
  line-height: normal;
  text-transform: capitalize;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.brand.contrastText};
  margin-bottom: 6px;
`;

export const StyledDropdownMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    color: theme.palette.mode === 'light' ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '& .MuiMenuItem-root': {
      '&:hover': {
        color: theme.palette.primary.main,
        // SVT-2787 - Should look at using a theme colour rather than a hard coded colour.
        backgroundColor: "rgba(255,255,255,.8)",

        '& .MuiRadio-root': {
          color: theme.palette.primary.main,

          '&.Mui-checked': {
            color: theme.palette.secondary.main,
          }
        },
      },
    },
  },
}));

export const StyledRadio = styled(Radio)(({ theme }) => ({
  '&': {
    color: theme.palette.mode === 'light' ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
  },
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  }
}));

export const Container = styled('div')`
  display: inherit;
`;
