import type { Order } from '@samsonvt/shared-types/orderLambda';

export type SortDirection = 'asc' | 'desc';
export type CurrentSortOption = { sortBy: SortOption; sortDirection: SortDirection };

export const viewFilterOptions = {
  ALL_ORDERS: 'All orders',
  ACTIVE_ORDERS: 'Active orders',
  COMPLETED_ORDERS: 'Completed orders',
} as const;

type ViewFilterOptions = typeof viewFilterOptions;
export type ViewFilterOption = ViewFilterOptions[keyof ViewFilterOptions];

export const sortOptions = {
  CONFIRMATION_NUMBER: 'Confirmation number',
  DATE: 'Date',
  COMPANY: 'Company',
  TOTAL: 'Total',
  STATUS: 'Status',
} as const;

type SortOptions = typeof sortOptions;
export type SortOption = SortOptions[keyof SortOptions] | '';


const sortByNumber = (prevOrder: Order, nextOrder: Order) => {
  const nextName = nextOrder.orderId;
  const prevName = prevOrder.orderId;
  return nextName.localeCompare(prevName);
};

const sortByDate = (prevOrder: Order, nextOrder: Order) => {
  const nextDate = new Date(nextOrder.timestamp);
  const prevDate = new Date(prevOrder.timestamp);
  return (nextDate as any) - (prevDate as any);
};

const sortByCompany = (prevOrder: Order, nextOrder: Order) => {
  const nextName = nextOrder.companyName;
  const prevName = prevOrder.companyName;
  return nextName.localeCompare(prevName);
};

const getPriceCompareValue = (order: Order) => {
  const price = order.totalPrice.amount;
  if (price === 'por') return -1;
  return price;
};

const sortByTotal = (prevOrder: Order, nextOrder: Order) => {
  const nextTotal = getPriceCompareValue(nextOrder);
  const prevTotal = getPriceCompareValue(prevOrder);
  return nextTotal - prevTotal;
};

const sortByStatus = (prevOrder: Order, nextOrder: Order) => {
  const nextStatus = nextOrder.status;
  const prevStatus = prevOrder.status;
  return nextStatus.localeCompare(prevStatus);
};

export function getSortingFunc(sortBy: SortOption) {
  switch (sortBy) {
    case sortOptions.CONFIRMATION_NUMBER:
      return sortByNumber;
    case sortOptions.DATE:
      return sortByDate;
    case sortOptions.COMPANY:
      return sortByCompany;
    case sortOptions.TOTAL:
      return sortByTotal;
    case sortOptions.STATUS:
      return sortByStatus;
    default:
      return sortByDate;
  }
}

export const getOrderedSortingFunc = (sortBy: SortOption, direction: SortDirection) => {
  const sortFunc = getSortingFunc(sortBy);
  return direction === 'asc' ? sortFunc : (prevOrder: Order, nextOrder: Order) => sortFunc(nextOrder, prevOrder);
};

const isActive = (order: Order) => order.status === 'ACTIVE';
const isCompleted = (order: Order) => order.status === 'COMPLETE';
const allOrders = (order: Order) => order;

export function getFilterFunc(viewFilterOption: ViewFilterOption) {
  switch (viewFilterOption) {
    case viewFilterOptions.ACTIVE_ORDERS:
      return isActive;
    case viewFilterOptions.COMPLETED_ORDERS:
      return isCompleted;
    case viewFilterOptions.ALL_ORDERS:
      return allOrders;
    default:
      return allOrders;
  }
}

export const getSearchFunc = (search: string) => {
  const searchLower = search.toLowerCase();
  return (order: Order) => {
    const { orderId, companyName } = order;
    const orderIdLower = orderId.toLowerCase();
    const companyNameLower = companyName.toLowerCase();
    return orderIdLower.includes(searchLower) || companyNameLower.includes(searchLower);
  };
};
