import { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material';

import { StyledLogo } from 'src/templates/UnauthenticatedTemplate';

import samsonLogoLarge from 'src/assets/logo-default.svg';
import { TenantContext } from 'src/providers/Tenant';
import { useUser } from 'src/providers/User';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MUIDivider from '@mui/material/Divider';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { useLocation } from 'react-router-dom';
import { LoadingSpinner } from '../Loading';
import { SendPasswordResetForm } from './SendPasswordResetForm';
import { StyledMUIButtonLink } from '../Button';
import { Toast, useTrigger } from '../Toast';
import SendPasswordResetSent from './SendResetPasswordSent';

function SendResetPasswordContainer() {
  const [loading, setLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const { logoUrl } = useContext(TenantContext);
  const { forgotPassword } = useUser();
  const location = useLocation();

  const searchParams = new URLSearchParams(location?.search);
  const redirectedError = searchParams.get('redirectedError');

  const [toast, setToast] = useTrigger();
  const [toastMessage, setToastMessage] = useState<string | undefined>();

  useEffect(() => {
    if (redirectedError === 'true') {
      setToast();
      setToastMessage('You have been redirected because an error occured, please request a new password');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectedError]);

  return resetEmailSent ? (
    <SendPasswordResetSent logoUrl={logoUrl ?? samsonLogoLarge}/>
  ) : (
    <StyledSendPasswordResetContainer>
      {loading ? <LoadingSpinner width="320" /> : <StyledLogo src={logoUrl || samsonLogoLarge} />}
      {resetEmailSent ? (
        <SendPasswordResetSent />
      ) : (
        <>
          <SendPasswordResetForm
            setLoading={setLoading}
            setResetEmailSent={setResetEmailSent}
            forgotPassword={forgotPassword}
          />
          <StyledDivider />
          <BackToLoginButton to="/" startIcon={<ChevronBack icon={faChevronLeft} />}>
            Back to login
          </BackToLoginButton>
        </>
      )}

      <Toast dependency={toast} severity="error" title="Please request a new password" message={toastMessage || ''} />
    </StyledSendPasswordResetContainer>
  );
}

export default SendResetPasswordContainer;

const StyledSendPasswordResetContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 320px;
`;

const StyledDivider = styled(MUIDivider)`
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
  height: 2px;
  margin-top: 3.8rem;
`;

const BackToLoginButton = styled(StyledMUIButtonLink)`
  background-color: transparent;
  box-shadow: none;
  border: 1px solid ${({ theme }) => theme.palette.brand.contrastText};
  color: ${({ theme }) => theme.palette.brand.contrastText};
  width: 50%;
  margin: 0;
  margin-top: 1rem;
  white-space: nowrap;
  min-width: 160px;

  :hover {
    background-color: ${({ theme }) => theme.palette.whiteGlass};
  }
`;

const ChevronBack = styled(FontAwesomeIcon)`
  font-size: 1rem !important; // use important to override more specific mui font-size
  margin: 0 1rem;
`;
