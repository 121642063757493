import { useContext } from 'react';
import { RepairDetailContext } from 'src/providers/repairs/RepairDetails';
import Description from 'src/components/Product/Mobile/Panels/WorkInstructions/RepairText/Description';
import { BottomBar } from 'src/components/Product/Mobile/Panels/WorkInstructions/RepairText/styles';
import { StepButtons } from "./StepButtons";

export interface ProductParams {
  productId: string;
  section: string;
}

export function RepairText() {
  const repairDetails = useContext(RepairDetailContext);

  if (!repairDetails) {
    return null;
  }

  const { step } = repairDetails;

  return (
    <BottomBar data-testid="repair-bar">
      <Description description={step.description} />
      <StepButtons/>
    </BottomBar>
  );
}
