import type { Order } from '@samsonvt/shared-types/orderLambda';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useParams } from 'react-router';
import { getOrderById, patchOrder, resendOrderEmailToCustomer, resendOrderEmailToSupplier } from 'src/services/api';

export function useOrderDetails() {
  const { orderId } = useParams<{ orderId: string }>();
  const queryClient = useQueryClient();

  const receivedOrdersQuery = () => {
    const { token } = axios.CancelToken.source();
    return getOrderById(orderId, token);
  };

  const {
    isLoading: isLoadingOrderDetails,
    data: orderDetails,
    isError: isErrorOrderDetails
  } = useQuery(['receivedOrdersDetails', orderId], receivedOrdersQuery);

  const {
    mutate: resendEmailToSupplierMutation,
    isLoading: isLoadingResendEmailToSupplier,
    isError: isErrorResendEmailToSupplier,
    isSuccess: isSuccessResendEmailToSupplier
  } = useMutation(resendOrderEmailToSupplier);

  const {
    mutate: resendEmailToCustomerMutation,
    isLoading: isLoadingResendEmailToCustomer,
    isError: isErrorResendEmailToCustomer,
    isSuccess: isSuccessResendEmailToCustomer
  } = useMutation(resendOrderEmailToCustomer);

  const {
    mutate: completeOrder,
    isLoading: isLoadingCompletedOrder,
    isError: isErrorCompletedOrder
  } = useMutation(patchOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries(['receivedOrdersDetails']);
    }
  });

  const markAsCompleted = (orderId: string) => completeOrder(orderId);
  const sendMailToSupplier = (orderId: string) => resendEmailToSupplierMutation(orderId);
  const sendMailToCustomer = (orderId: string) => resendEmailToCustomerMutation(orderId);

  return {
    placeHolderOrder,
    markAsCompleted,
    sendMailToSupplier,
    isLoadingOrderDetails,
    isLoadingCompletedOrder,
    isLoadingResendEmailToSupplier,
    sendMailToCustomer,
    isLoadingResendEmailToCustomer,
    isErrorResendEmailToCustomer,
    isSuccessResendEmailToCustomer,
    isErrorCompletedOrder,
    isSuccessResendEmailToSupplier,
    isErrorOrderDetails,
    isErrorResendEmailToSupplier,
    orderDetails
  };
}

const placeHolderOrder: Order = {
  customerReference: '',
  accountId: '',
  lastName: '',
  companyName: '',
  orderId: '',
  status: 'ACTIVE',
  address: {
    country: '',
    address2: '',
    city: '',
    address1: '',
    postalCode: ''
  },
  createdByEmail: '',
  items: [],
  discount: 0,
  firstName: '',
  messageToSeller: '',
  'vtaas-ui:checkout:customerAccountField': '',
  telephone: '',
  timestamp: '',
  createdById: '',
  totalPrice: {
    amount: 0,
    currencyCode: ''
  }
};
