import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FullscreenLoadingSpinner } from 'src/components/Loading/FullscreenLoadingSpinner';
import { Toast } from 'src/components/Toast';
import { useUser } from 'src/providers/User';
import { CognitoTokenEndPointResponse } from 'src/services/samsonVtUser';
import * as log from 'src/services/log';
import { authorizationCodeRedirectUrl } from 'src/utils/urls';
import { cognitoDomain } from 'src/utils/cognito';

const tokenEndPointRequest = (code: string): Promise<AxiosResponse<CognitoTokenEndPointResponse>> =>
  axios.post(
    `${cognitoDomain}` +
      `/oauth2/token?` +
      `client_id=${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}&` +
      `grant_type=authorization_code&` +
      `redirect_uri=${authorizationCodeRedirectUrl}&` +
      `code=${code}`,
    {},
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );

export default function AuthorizationCodePage() {
  const { initiateCognitoSession } = useUser();
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location?.search);
  const code = searchParams.get('code');
  const errorFromAuthenticationServer = searchParams.get('error');

  const {
    mutateAsync: exchangeCodeForTokens,
    isLoading: isLoadingExchangingCodeForTokens,
    isError: isErrorExchangingCodeForTokens,
  } = useMutation(['authorizationCode'], async (code: string) => tokenEndPointRequest(code), {
    onSuccess({ data }) {
      initiateCognitoSession(data);
      history.push('/product-library');
      window.location.reload();
    },
    onError(error: Error) {
      log.error(error); // enable TrackJS. This error will not show up in lambda logs
    },
  });

  useEffect(() => {
    if (code) {
      exchangeCodeForTokens(code);
    }
  }, [code, exchangeCodeForTokens]);

  if (isLoadingExchangingCodeForTokens) {
    return (
      <>
        <FullscreenLoadingSpinner />
        <Text>Logging in with SSO</Text>
      </>
    );
  }

  if (isErrorExchangingCodeForTokens) {
    return (
      <>
        <Icon icon={faTriangleExclamation} size="3x" />
        <Text>An error occured logging in with SSO. Please try again later.</Text>
        <Toast
          dependency={isErrorExchangingCodeForTokens}
          severity="error"
          title="Error logging in with SSO"
          message="Please try again later"
        />
      </>
    );
  }

  if (errorFromAuthenticationServer) {
    return (
      <>
        <Icon icon={faTriangleExclamation} size="3x" />
        <Text>{`Error returned from the authentication server: ${errorFromAuthenticationServer}. Please try again later.`}</Text>
        <Toast
          dependency={errorFromAuthenticationServer}
          severity="error"
          title="Error returned from the authentication server"
          message="Please try again later"
        />
      </>
    );
  }

  return null;
}

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 48%;
`;

const Text = styled(MUITypography)`
  position: absolute;
  top: 60%;
  width: 100%;
  text-align: center;
  font-weight: bold;
`;
