/* eslint-disable no-restricted-syntax */
import { useThree } from '@react-three/fiber';
import { History } from 'history';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { usePart } from 'src/services/part';
import { makePartUrl } from 'src/services/partUrl';
import { findRelatedPartNumber } from 'src/services/partUtils';
import { Object3D } from 'three';

import { useProduct } from 'src/providers/Product';
import { makeRaycaster } from './raycaster';

interface HoverParams {
  history: History;
  model: Object3D;
}

let isDragging = false; // SVT-2103 prevent selection on drag. Click always fires on drag end, so set on drag, unset on click. There is just one mouse so can be scoped to module.

export function HoverDetector({ history, model }: HoverParams) {
  const { camera, size, gl } = useThree();
  const { group, partPath, productId } = usePart();
  const { modelType } = useProduct();

  const currentHovered = useRef<[string, string] | undefined>(undefined);

  const canvasOffset = useMemo(() => {
    const { left, top } = gl.domElement.getBoundingClientRect();
    return { left, top };
  }, [gl.domElement]);

  const raycaster = useMemo(() => makeRaycaster(camera, size, canvasOffset), [camera, size, canvasOffset]);
  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (e.buttons) {
        isDragging = true;
        return; // Skip raycasting during dragging for performance.
      }

      const intersections = raycaster.intersectObjects(model.children, e);

      for (const intersection of intersections) {
        const { object } = intersection;

        // Define a function to handle the common part number finding logic
        const handlePartNumberFinding = (targetObject: Object3D | null) => {
          if (targetObject && group) {
            const partNumber = findRelatedPartNumber(targetObject.name, group);
            if (partNumber) {
              document.body.style.cursor = 'pointer';
              currentHovered.current = [partNumber, targetObject.name];
              return true;
            }
          }
          return false;
        };

        if (
          // First check if the intersected object has part number and can be selected.
          handlePartNumberFinding(object) ||
          // If the object is a child of a multi-material mesh, we need to check the parent's name for the part number
          // Some meshes have underscores in their names, so we need to check the parent's name for the part number
          // Meshes that are part of a multi-material have underscore and a number (e.g., 'meshName_1', 'meshName_2').
          // Object name can be CAD operator defined like 'Fillet', 'Sweep', 'Extrude', etc. so we need to check the parent's name for the part number
          handlePartNumberFinding(object.parent)
        )
          return;
      }

      // Reset cursor and hovered state if no clickable part found
      document.body.style.cursor = 'default';
      currentHovered.current = undefined;
    },
    [model, group, raycaster]
  );

  const onMouseMove = debounce(handleMouseMove, 10, { leading: true });

  const onClick = useCallback(() => {
    if (isDragging) {
      isDragging = false;
      return;
    }
    if (productId && partPath && currentHovered.current) {
      const currentLocation = history.location.pathname + history.location.search;
      const [partNumber, meshId] = currentHovered.current;
      const hoveredPartUrl = makePartUrl(productId, partNumber, {
        group: partPath.pathHash,
        mesh: meshId,
        modelType,
      });

      if (hoveredPartUrl !== currentLocation) {
        history.push(hoveredPartUrl);
      }
    }
  }, [history, partPath, productId, currentHovered, modelType]);

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove, false);
    document.addEventListener('click', onClick, false);
    document.addEventListener('touchend', onClick, false);

    return () => {
      document.removeEventListener('mousemove', onMouseMove, false);
      document.removeEventListener('click', onClick, false);
      document.removeEventListener('touchend', onClick, false);
    };
  }, [onMouseMove, onClick]);

  return null;
}
