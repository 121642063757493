import { ISO4217 } from '@samsonvt/shared-types/orderLambda';
import { getCurrencyName } from "src/services/formatCurrency";
import { FormControlLabel, MenuItem, Typography } from '@mui/material';
import { faDollarSign, faEuroSign, faPoundSign } from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Icon, StyledRadio } from "./styles";

interface DropdownItemProps {
  onClick: () => void;
  currency: ISO4217;
}

function getFAIconFromCurrency(currency: string): IconDefinition {
  switch (currency) {
    case 'EUR':
      return faEuroSign
    case 'USD':
      return faDollarSign
    case 'GBP':
    default:
      return faPoundSign
  }
}

export default function DropdownItem({ onClick, currency }: DropdownItemProps) {
  const label = (
    <Typography>
      {getCurrencyName(currency)} - {currency} <Icon sx={{ marginBottom: 'unset', color: 'unset' }} icon={getFAIconFromCurrency(currency)} />
    </Typography>
  );

  return (
    <MenuItem data-testid="currency-switcher-item" onClick={onClick}>
      <FormControlLabel
        key={currency}
        value={currency}
        control={<StyledRadio />}
        label={label}
        labelPlacement="start"
        sx={{ marginLeft: 0, justifyContent: 'space-between', flexGrow: 1 }}
      />
    </MenuItem>
  );
}
