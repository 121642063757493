import { PartNode } from '@samsonvt/shared-types/productLambda';
import { useContext, useMemo, useState } from 'react';
import { Scene } from 'src/components/Scene';
import { useCurrentTabName } from 'src/hooks/useCurrentTabName';
import { ModelContext } from 'src/providers/Model';
import { useProduct } from 'src/providers/Product';
import { RepairDetailContext } from 'src/providers/repairs/RepairDetails';
import { usePart } from 'src/services/part';
import { CanvasFlexWrapper } from './CanvasFlexWrapper';
import { ErrorMessage } from './ErrorMessage';
import LoadingOverlay from './LoadingOverlay';

interface GetAssemblyHighlightsParams {
  currentPartNode: PartNode;
  hiddenVariantNames?: string[];
}
/**
 * Parses the current part node and returns a list of object3DNames to highlight
 * Needs to go through children to include variants
 * @returns list of object3DNames to highlight
 */
function getAssemblyHighlights({ currentPartNode, hiddenVariantNames }: GetAssemblyHighlightsParams) {
  const get3dNames = (child: PartNode): string | string[] => {
    if (child.object3DName) return hiddenVariantNames?.includes(child.object3DName) ? [] : child.object3DName;

    // variant case
    if (child.children) return child.children.flatMap(get3dNames);

    return [];
  };

  const assemblyHighlight = currentPartNode.children.flatMap(get3dNames);

  return assemblyHighlight;
}

const isString = (x: string | undefined): x is string => typeof x === 'string';

export function ThreeJsContainer({ fullControls }: { fullControls: boolean }) {
  const { model, animations, loadingProgress, isError } = useContext(ModelContext);
  const [fullyRendered, setFullyRendered] = useState(false);

  const { step: currentRepairStep } = useContext(RepairDetailContext) || {};
  const part = usePart();
  const { current, focalPoint } = part;
  const { hiddenVariantNames } = useProduct();
  const isPartCatalogue = useCurrentTabName() === 'parts-catalogue';

  const fadeModel = useMemo(
    () => ({
      show: focalPoint?.show ?? currentRepairStep?.showNames,
      hide: focalPoint?.hide ?? currentRepairStep?.hideNames,
      hiddenVariantNames: currentRepairStep ? [] : hiddenVariantNames,
    }),
    [focalPoint, currentRepairStep, hiddenVariantNames]
  );

  const isCurrentAnAssembly = !!current?.price && !!current.children.length;
  const assemblyHighlight = isCurrentAnAssembly
    ? getAssemblyHighlights({ currentPartNode: current, hiddenVariantNames })
    : [];
  const currentPartHighlight = [current?.object3DName].concat(assemblyHighlight).filter(isString);

  const highlight = useMemo(
    () =>
      currentRepairStep?.highlight?.names || // Old repair highlights, deprecated with https://bitbucket.org/samson_vt/svt-blender-plugin/pull-requests/52
      currentRepairStep?.highlightNames || // New naming
      currentPartHighlight, // Part highlight
    // TODO: Highlight gets' updated with every usePart() destructuration. Fix it on top level and remove eslint disable.
    // Updated deps array to include all possible values that can be used in highlight to fix https://samsonvt.atlassian.net/browse/SVT-2008
    [currentPartHighlight, currentRepairStep?.highlight?.names, currentRepairStep?.highlightNames]
  );

  const clip = useMemo(() => {
    const clipName = currentRepairStep?.animationClipName || focalPoint?.animation;
    return clipName ? animations?.find((clip) => clip.name === clipName) : undefined;
  }, [currentRepairStep, focalPoint, animations]);

  const { orbitCenter, position } = useMemo(
    () => ({
      orbitCenter: focalPoint?.target ?? currentRepairStep?.orbitCenter,
      position: focalPoint?.position ?? currentRepairStep?.cameraPosition,
    }),
    [focalPoint, currentRepairStep]
  );

  if (isError) {
    return <ErrorMessage data-testid="three-container-error">3d render currently unavailable</ErrorMessage>;
  }

  return (
    <CanvasFlexWrapper data-testid="three-container">
      {model && (
        <Scene
          setFullyRendered={setFullyRendered}
          scene={model}
          clip={clip}
          fadeModel={fadeModel}
          highlight={highlight}
          orbitCenter={orbitCenter}
          position={position}
          isPartCatalogue={isPartCatalogue}
          fullControls={fullControls}
        />
      )}
      {(!fullyRendered || !model) && <LoadingOverlay progress={loadingProgress} />}
    </CanvasFlexWrapper>
  );
}
