import { styled } from '@mui/material';
import MUIDivider from '@mui/material/Divider';
import MUIList from '@mui/material/List';
import MUITypography from '@mui/material/Typography';
import type { DiscountedPartPrice } from '@samsonvt/shared-types/partsTable';
import { LoadingSpinner } from 'src/components/Loading';
import useTranslation from 'src/hooks/useTranslation';
import type { CartEntry } from 'src/providers/ShoppingCart';
import useFormattedPrice from 'src/services/formatCurrency';
import { CheckoutCartStyles, CheckoutListFade } from '../styles';
import { DesktopCheckoutCartItem } from './DesktopCheckoutCartItem';

interface CheckoutCartProps {
  cart: CartEntry[];
  cartTotal: DiscountedPartPrice;
  isFetching: boolean;
}

export function DesktopCheckoutCart({ cart, cartTotal, isFetching }: CheckoutCartProps) {
  const totalPrice = cart.length === 0 ? undefined : cartTotal;
  const checkoutCustomCaveat = useTranslation('vtaas-ui:checkout:customCaveat', '');

  return (
    <CheckoutCartStyles>
      <CheckoutCartTitle>Your shopping cart</CheckoutCartTitle>
      <ShoppingCartDivider />
      {isFetching ? (
        <LoadingSpinner height="200" width="200" />
      ) : (
        <CheckoutList>
          {cart.map((item) => (
            <DesktopCheckoutCartItem key={item.partNumber} {...item} />
          ))}
        </CheckoutList>
      )}

      <CheckoutListFade />
      <ShoppingCartDivider />
      <ColumnContainer>
        <CheckoutCartTotalSection>
          <CheckoutTotalLabel>Total: </CheckoutTotalLabel>
          <CheckoutTotal data-testid="checkout-cart-total-price">
            {useFormattedPrice({ price: totalPrice })}
          </CheckoutTotal>
        </CheckoutCartTotalSection>
        {checkoutCustomCaveat ? (
          <CustomCaveatsContainer>
            <CustomCaveatsText variant="h6">{checkoutCustomCaveat}</CustomCaveatsText>
          </CustomCaveatsContainer>
        ) : null}
      </ColumnContainer>
    </CheckoutCartStyles>
  );
}

const CheckoutCartTitle = styled(MUITypography)`
  display: inline;
  margin: 1rem auto;
  text-align: center;
`;
CheckoutCartTitle.defaultProps = { variant: 'h6', fontWeight: 'bold' };

const CheckoutTotalLabel = styled(MUITypography)`
  text-align: center;
  margin: auto 1rem;
`;
CheckoutTotalLabel.defaultProps = { variant: 'body1', fontWeight: 'bold' };

const CheckoutCartTotalSection = styled('div')`
  display: flex;
  align-items: end;
  justify-content: flex-end;
`;

const ColumnContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
  padding: 0 1rem;
`;

const ShoppingCartDivider = styled(MUIDivider)`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
`;

const CheckoutTotal = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.grey[900]};
`;

CheckoutTotal.defaultProps = {
  variant: 'h6',
  fontWeight: 'bold',
};

const CheckoutList = styled(MUIList)`
  width: 100%;
  flex-grow: 1;
  font-family: Arial;
  padding: 0 1rem;
  color: ${({ theme }) => theme.palette.grey[900]};
  & li:nth-of-type(even) {
    background-color: ${({ theme }) => theme.palette.whiteGlass};
  }

  ${({ theme }) => theme.mixins.hideScrollbar}
`;

const CustomCaveatsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  width: 100%;
`;
const CustomCaveatsText = styled(MUITypography)`
  text-align: end;
  font-size: 12px;
  font-weight: bold;
`;
