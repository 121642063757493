// Inspired with https://usehooks.com/useLocalStorage/
import { useEffect, useState } from 'react';
import { getFromStorage, addToStorage } from 'src/services/blobStorage';
import { error as logError } from 'src/services/log';

export default function useIndexedDBState<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(initialValue);

  useEffect(() => {
    const setFromStorage = async () => {
      try {
        const item = await getFromStorage(key);
        if (item) {
          setStoredValue(fromArrayBuffer(item));
        }
      } catch (error) {
        logError(error as Error);
      }
    };
    setFromStorage();
  }, [key]);

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      addToStorage(key, toArrayBuffer(valueToStore as Object));
    } catch (error) {
      logError(error as Error);
    }
  };

  return [storedValue, setValue] as const;
}

export const addToIndexedDBAsBuffer = (key: string, value: Object) => addToStorage(key, toArrayBuffer(value));

export const getBufferFromIndexedDB = async (key: string) => {
  const value = await getFromStorage(key);
  return value && fromArrayBuffer(value);
};

const textEncoder = new TextEncoder();
const textDecoder = new TextDecoder();
const toArrayBuffer = (obj: Object) => textEncoder.encode(JSON.stringify(obj)).buffer;
const fromArrayBuffer = (arrayBuffer: ArrayBuffer) => JSON.parse(textDecoder.decode(arrayBuffer));
