import { faFileCsv } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import MUIButton from '@mui/material/Button';
import MUITypography from '@mui/material/Typography';
import type { Order } from '@samsonvt/shared-types/orderLambda';
import useFormattedPrice from 'src/services/formatCurrency';
import { formatDateWithTime } from 'src/services/formatDate';
import { downloadFile } from 'src/utils/downloadFile';

interface ExportCsvButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  orders: Order[];
}

export function ExportCsvButton({ orders }: ExportCsvButtonProps) {
  const ordersExportData = useOrdersExportData(orders);
  const handleExport = () => exportTableToCsv(ordersExportData);
  return (
    <ExportButton onClick={handleExport} data-testid="download-csv-button">
      <DownloadIcon icon={faFileCsv} />
      <MUITypography fontWeight="bold">Export table to CSV</MUITypography>
    </ExportButton>
  );
}

/**
 * @returns {string[][]} - array of strings that represent order data.
 * First array is like header (general order data), the rest are order items.
 */
function useOrderExportData(order: Order) {
  const id = order.eCommerceOrderName || order.orderId;
  const date = formatDateWithTime(order.timestamp);
  const { companyName } = order;
  const { amount: price, currencyCode: currency } = order.totalPrice;
  const formattedPrice = useFormattedPrice({ price, currency });
  const total = typeof formattedPrice === 'string' ? formattedPrice : 'Price on request';
  const { status } = order;
  const { items } = order;
  const orderArray = [id, date, companyName, total, status, '', '', '', ''];
  const orderItemsArrays = items.map((item) => {
    const { partNumber, partName, unitPrice, quantity } = item;
    return ['', '', '', '', '', partName, partNumber, String(quantity), String(unitPrice)];
  });
  return [orderArray, ...orderItemsArrays];
}

function useOrdersExportData(orders: Order[]) {
  const header = [
    'Confirmation number',
    'Date',
    'Company name',
    'Total',
    'Status',
    'Part Ordered',
    'Part Number',
    'Quantity',
    'Part Cost',
  ];
  const ordersArrays = orders.flatMap(useOrderExportData);
  const rows = [header, ...ordersArrays];

  return rows;
}

function exportTableToCsv(ordersExportData: string[][]) {
  const csvContent = ordersExportData
    .map((row) => row.map((item) => String(item).replaceAll(',', '.')))
    .map((row) => row.join(','))
    .join('\n');
  const csvFile = new Blob([csvContent], { type: 'text/csv' });

  downloadFile(csvFile, 'Orders');
}

const DownloadIcon = styled(FontAwesomeIcon)`
  margin-right: 0.3rem;
`;

const ExportButton = styled(MUIButton)`
  text-transform: none;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.grey[900]};
  background-color: ${({ theme }) => theme.palette.common.white};
  margin-left: 0.5rem;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }
`;

ExportButton.defaultProps = { variant: 'outlined' };
