import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, styled } from '@mui/material';
import { faRightFromBracket, faCircleUser } from '@fortawesome/pro-light-svg-icons';
import { getCurrencySymbol } from 'src/services/formatCurrency';
import { ISO4217 } from '@samsonvt/shared-types/orderLambda';
import MUITypography from '@mui/material/Typography';
import { StyledMUIButton, StyledMUIButtonLink } from '../Button';

interface MobileProductMenuFooterProps {
  logout: () => Promise<void>;
  currency: ISO4217;
  triggerMobileCurrencyMenu: () => void;
  currencies: ISO4217[] | undefined;
}

export default function MobileProductMenuFooter({
  logout,
  currency,
  triggerMobileCurrencyMenu,
  currencies,
}: MobileProductMenuFooterProps) {
  return (
    <MobileProductMenuFooterWrapper>
      {currencies && currencies.length > 1 && (
        <MobileProductMenuCurrencyButton onClick={triggerMobileCurrencyMenu}>
          <MobileProductMenuCurrencySymbol>{getCurrencySymbol(currency)}</MobileProductMenuCurrencySymbol>
          Currency
        </MobileProductMenuCurrencyButton>
      )}

      <MobileProductMenuFooterButton to="/my-account">
        <MobileProductMenuFooterMyAccountIcon icon={faCircleUser} />
        My account
      </MobileProductMenuFooterButton>

      <MobileProductMenuDivider />

      <MobileProductMenuFooterButton to="/" onClick={logout} sx={{ padding: '1rem 0.5rem' }}>
        <MobileProductMenuFooterLogoutIcon icon={faRightFromBracket} />
        Log out
      </MobileProductMenuFooterButton>
    </MobileProductMenuFooterWrapper>
  );
}

const MobileProductMenuFooterWrapper = styled('div')`
  background-color: ${({ theme }) => theme.palette.primary.light};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 0.5rem;
  margin-top: auto;
`;

const MobileProductMenuCurrencyButton = styled(StyledMUIButton)`
  background-color: transparent;
  box-shadow: none;
  color: ${({ theme }) => theme.palette.common.white};
  padding: 0 0.5rem;
  font-size: 1rem;
  font-weight: normal;
  justify-content: flex-start;
  margin: 0 1rem;

  :hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

const MobileProductMenuFooterButton = styled(StyledMUIButtonLink)`
  background-color: transparent;
  box-shadow: none;
  color: ${({ theme }) => theme.palette.common.white};
  padding: 1rem 0.5rem;
  font-size: 1rem;
  font-weight: normal;
  justify-content: flex-start;

  :hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

const MobileProductMenuFooterMyAccountIcon = styled(FontAwesomeIcon)`
  font-size: 1.9rem;
  margin-right: 0.75rem;
`;

const MobileProductMenuFooterLogoutIcon = styled(FontAwesomeIcon)`
  font-size: 1.9rem;
  margin-right: 0.75rem;
`;

const MobileProductMenuDivider = styled(Divider)`
  height: 2px;
  width: 85%;
  background-color: ${({ theme }) => theme.palette.whiteGlass};
  align-self: center;
`;

MobileProductMenuDivider.defaultProps = {
  orientation: 'horizontal',
};

const MobileProductMenuCurrencySymbol = styled(MUITypography)`
  font-size: 2rem;
  margin-left: 0.4rem;
  margin-right: 1rem;
`;
