import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { SelectChangeEvent, styled } from '@mui/material';
import MUIBox from '@mui/material/Box';
import MUIFormControl from '@mui/material/FormControl';
import MUIMenuItem from '@mui/material/MenuItem';
import MUITypography from '@mui/material/Typography';
import { Order } from '@samsonvt/shared-types/orderLambda';
import { SearchField } from 'src/components/SearchField';
import { StyledSelectField } from 'src/components/Select';
import { useStickHeaderWithIntersectionObserver } from 'src/hooks/useStickHeaderWithIntersectionObserver';
import { ViewFilterOption, viewFilterOptions } from '../../filters';
import { MagnifyingGlassSearchIcon } from '../../styles';
import { ExportCsvButton } from '../../ExportCsvButton';

export interface ReceivedOrdersTableHeaderProps {
  currentViewFilter: ViewFilterOption;
  searchPhrase: string;
  displayOrders: Order[];
  setViewFilter: (viewFilterOption: ViewFilterOption) => void;
  setSearchPhrase: (searchPhrase: string) => void;
}

export function ReceivedOrdersTableHeader({
  currentViewFilter,
  searchPhrase,
  displayOrders,
  setViewFilter,
  setSearchPhrase,
}: ReceivedOrdersTableHeaderProps) {
  const isHeaderInNormalPosition = useStickHeaderWithIntersectionObserver();
  const handleViewFilter = (e: SelectChangeEvent<unknown>) => {
    setViewFilter(e.target.value as ViewFilterOption);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(e.target.value);
  };

  return (
    <StickyHeader id="sticky-header" isHeaderInNormalPosition={isHeaderInNormalPosition}>
      <MUITypography>View:</MUITypography>
      <TableControls>
        <SelectContainer>
          <OrderFilterSelect sx={{ minWidth: '190px', marginLeft: '0' }}>
            <StyledSelectField
              value={currentViewFilter}
              name="completedActiveFilter"
              onChange={handleViewFilter}
              data-testid="received-orders-status-filter"
            >
              <MUIMenuItem value={viewFilterOptions.ALL_ORDERS}>{viewFilterOptions.ALL_ORDERS}</MUIMenuItem>
              <MUIMenuItem value={viewFilterOptions.ACTIVE_ORDERS} data-testid="received-orders-active-filter">
                {viewFilterOptions.ACTIVE_ORDERS}
              </MUIMenuItem>
              <MUIMenuItem value={viewFilterOptions.COMPLETED_ORDERS} data-testid="received-orders-completed-filter">
                {viewFilterOptions.COMPLETED_ORDERS}
              </MUIMenuItem>
            </StyledSelectField>
          </OrderFilterSelect>
        </SelectContainer>
        <RightSection>
          <OrdersSearchField
            InputProps={{ endAdornment: <MagnifyingGlassSearchIcon icon={faMagnifyingGlass} /> }}
            placeholder="Search for a confirmation number or company name"
            value={searchPhrase}
            onChange={handleSearch}
            data-testid="received-orders-search"
          />
          <ExportCsvButton orders={displayOrders} />
        </RightSection>
      </TableControls>
    </StickyHeader>
  );
}

const ReceivedOrdersHeader = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`;
ReceivedOrdersHeader.defaultProps = { variant: 'h5' };

const StickyHeader = styled(MUIBox)<{ isHeaderInNormalPosition: boolean }>`
  position: sticky;
  top: -1px;
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: 1px solid
    ${({ theme, isHeaderInNormalPosition }) => (!isHeaderInNormalPosition ? theme.palette.grey[900] : 'transparent')};
  & .is-pinned {
    background-color: red;
  }
`;

const OrdersSearchField = styled(SearchField)`
  height: auto;
`;

const TableControls = styled(MUIBox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckedText = styled(MUITypography)`
  font-weight: bold;
  padding-left: 1rem;
`;
CheckedText.defaultProps = { variant: 'body2' };

const SelectContainer = styled(MUIBox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RightSection = styled(MUIBox)`
  display: flex;
  flex-direction: row;
  margin: 1rem;
  height: calc(1.4375em + 2rem); // Height of the select field
`;

const OrderFilterSelect = styled(MUIFormControl)`
  width: 35%;
  margin: 1rem;
  min-width: 190px;

  & .MuiSelect-select {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

OrderFilterSelect.defaultProps = {
  variant: 'filled',
  size: 'small',
};
