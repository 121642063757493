import { styled, alpha } from '@mui/material';

import MUITypography from '@mui/material/Typography';
import MUIInputLabel from '@mui/material/InputLabel';
import MUIDialogTitle from '@mui/material/DialogTitle';
import MUIDialogContent from '@mui/material/DialogContent';
import MUIDialogActions from '@mui/material/DialogActions';
import MUIDialog from '@mui/material/Dialog';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledMUIButton } from '../Button';
import { WhiteTextField } from '../TextInput/WhiteTextField';
import { LoadingSpinner } from '../Loading';

export const PseudoInputLabel = styled(MUIInputLabel)`
  font-size: 0.75rem;
`;

export const SaveButton = styled(StyledMUIButton)`
  // Controlled height & width for spinner positioning
  height: 2.5rem;
  width: 5rem;
`;

SaveButton.defaultProps = { ...StyledMUIButton.defaultProps, size: 'large' };

export const Spinner = styled(LoadingSpinner)`
  min-width: 5rem;
  width: 5rem;
  height: 5rem;
`;

export const TextButton = styled(StyledMUIButton)`
  background-color: ${({ theme }) => theme.palette.grey[900]};
  box-shadow: none;
  color: ${({ theme }) => theme.palette.grey[100]};
  &:hover {
    box-shadow: none;
    background-color: ${({ theme }) => alpha(theme.palette.secondary.main, 0.08)};
  }
`;
TextButton.defaultProps = { size: 'large' };

export const StyledMUIDialogTitle = styled(MUIDialogTitle)`
  padding: 1.3rem 2rem;
  background-color: ${({ theme }) => theme.palette.grey[900]};
  color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: 4px 4px 0px 0px;
`;

export const StyledMUIDialogActions = styled(MUIDialogActions)`
  padding: 1rem 2rem;
  gap: 2rem;
  background-color: ${({ theme }) => theme.palette.grey[900]};
  border-radius: 0px 0px 4px 4px;
`;

export const StyledMUIDialogContent = styled(MUIDialogContent)`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: fit-content;
`;

export const StyledMUIDialogContentEditDetails = styled(MUIDialogContent)`
  padding: 0 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: fit-content;
`;

export const TextField = styled(WhiteTextField)`
  width: 100%;
`;

export const FlexColumn = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FlexRow = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const BlackBorder = styled('div')`
  border: 1px solid black;
  background: ${({ theme }) =>
    theme.palette.grey['500']}; // Give it background same as part thumbnail has, so that it wont blink on load
`;
export const PseudoInputPadding = styled('div')`
  padding: 0.75rem;
`;

export const LockButton = styled('div')`
  display: flex;
  gap: 1rem;
  cursor: pointer;
  padding: 0 0 0 0.75rem;
  p {
    margin: 0;
    text-decoration: underline;
    text-underline-offset: 4px;
    &:hover {
      text-decoration: none;
    }
  }
  width: fit-content;
`;

export const Divider = styled('hr')`
  border-color: ${({ theme }) => theme.palette.grey['300']};
`;

export const SnackBarTextContainer = styled('div')`
  padding: 1rem 1rem 0rem 1rem;
`;

export const BlueSnackBarText = styled(FlexRow)`
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 1rem;
  padding: 1rem 1rem;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.info.main};
`;

export const YellowSnackBarText = styled(FlexRow)`
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 1rem;
  padding: 1rem 1rem;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.warning.main};
`;

export const Scenario1SubTitle = styled(MUITypography)`
  font-size: 1rem;
  margin-left: 3rem;
`;

export const StyledInfoIcon = styled(FontAwesomeIcon)`
  padding: 0.5rem;
`;

export const EditPartDetailsLoadingSpinner = styled(LoadingSpinner)`
  margin: auto;
`;

export const StyledMuiDialog = styled(MUIDialog, { shouldForwardProp: (prop: string) => prop !== 'scrollEnabled' })<{
  scrollEnabled: boolean;
}>`
  .MuiDialog-container {
    height: ${({ scrollEnabled }) => (scrollEnabled ? 'auto' : '100%')};
  }
`;

export const ScrollableEditPartDetailsContainer = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'scrollEnabled'
})<{
  scrollEnabled: boolean;
}>`
  height: ${({ scrollEnabled }) => (scrollEnabled ? '70vh' : 'auto')};
  max-height: 650px;
  overflow-y: scroll;
`;

export const Banner = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4px;
  align-items: center;
  background-color: #757575;
  color: #f5f5f5;
  padding: 8px 6px 8px 6px;
  font-size: 12px;
  font-weight: 400;
  width: 200px;
  flex-wrap: wrap;
  p {
    max-width: 100%; //
    word-wrap: break-word;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }
`;

export const Span = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: max-content;
`;
