import { StandardBreadcrumbs } from 'src/components/Breadcrumbs/StandardBreadcrumbs';
import { ReceivedOrdersTable } from './ReceivedOrdersTable/ReceivedOrdersTable';
import ReceivedOrdersTableDescription from './ReceivedOrdersTable/ReceivedOrdersTableDescription';

export default function ReceivedOrders() {
  return (
    <>
      <StandardBreadcrumbs
        current={{ name: 'Received orders' }}
        parents={[{ name: 'My account', url: '/my-account' }]}
        sx={{ margin: '1rem 0' }}
      />
      <ReceivedOrdersTableDescription />
      <ReceivedOrdersTable />
    </>
  );
}
