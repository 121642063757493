import { authorizationCodeRedirectUrl } from 'src/utils/urls';
import { cognitoDomain } from 'src/utils/cognito';
import { SSOButton } from './styles';

interface SSOLoginButtonProps {
  id: string;
  displayName: string;
}

const constructSSOLoginURL = (idpId: string) =>
  `${cognitoDomain}/oauth2/authorize?` +
  `client_id=${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}&` +
  `response_type=code&` +
  `identity_provider=${idpId}&` +
  `scope=aws.cognito.signin.user.admin+email+openid+phone+profile&` +
  `redirect_uri=${authorizationCodeRedirectUrl}`;

export default function SSOLoginButton({ id, displayName }: SSOLoginButtonProps) {
  return <SSOButton href={constructSSOLoginURL(id)}>{displayName}</SSOButton>;
}
