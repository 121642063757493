import { styled } from '@mui/material';
import { MobileCheckoutForm } from './MobileCheckoutForm';
import type { CheckoutProps } from '../Checkout';

export default function MobileCheckout({
  cart,
  isOrderPOR,
  discountPercentage,
  currency,
  sendOrder,
  isFetching,
  goBack,
  defaultAccountDetails,
}: CheckoutProps) {
  return (
    <MobileCheckoutFormContainer>
      <MobileCheckoutForm
        cart={cart}
        isOrderPOR={isOrderPOR}
        discountPercentage={discountPercentage}
        currency={currency}
        sendOrder={sendOrder}
        isFetching={isFetching}
        goBack={goBack}
        defaultAccountDetails={defaultAccountDetails}
      />
    </MobileCheckoutFormContainer>
  );
}

const MobileCheckoutFormContainer = styled('div')`
  display: flex;
  justify-content: center;
`;
