import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AutocompleteRenderInputParams,
  InputAdornment,
  Autocomplete as MUIAutocomplete,
  styled,
  TextField,
} from '@mui/material';
import type { Supersession } from '@samsonvt/shared-types/partsTable';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import filterOptionsFunction, {
  uniqueKey,
} from 'src/components/Product/Mobile/Panels/PartsCatalogue/Autocomplete/filterOptions';
import SearchOption from 'src/components/Product/Mobile/Panels/PartsCatalogue/Autocomplete/SearchOption';
import {
  FullScreenPopper,
  StyledPopper,
} from 'src/components/Product/Mobile/Panels/PartsCatalogue/Autocomplete/styles';
import { useProduct } from 'src/providers/Product';
import { PartPathWithHash } from 'src/services/partPath';
import { makePartUrl } from 'src/services/partUrl';

const minimumSearchTermLength = 3;
const initialSearchTerm = '';

export interface SearchablePart extends PartPathWithHash, Supersession {} // Typing is slightly off. Option groups are not taken into account.

interface AutocompleteProps {
  fullscreen: boolean;
  closeSearch: () => void;
}

export const charactersToEscapeRegex = /[.*+?^${}()|[\]\\]/g;

export function Autocomplete({ fullscreen, closeSearch }: AutocompleteProps): JSX.Element {
  const [query, setQuery] = useState(initialSearchTerm);
  const history = useHistory();
  const { productId, partPaths, modelType } = useProduct();

  const enabled = query.length >= minimumSearchTermLength;
  const escapedQuery = query.replace(charactersToEscapeRegex, '\\$&');
  const regex = new RegExp(escapedQuery, 'gi');
  const popperComponent = fullscreen ? FullScreenPopper : StyledPopper;

  return (
    <MUIAutocomplete<SearchablePart, false, true, false> // <T (Type of options), Multiple, DisableClearable, FreeSolo>
      data-testid="bom-filter-input"
      disableClearable
      autoHighlight
      fullWidth
      size="small"
      getOptionLabel={(option) => option.part.name}
      isOptionEqualToValue={(option: SearchablePart, value: SearchablePart) => uniqueKey(option) === uniqueKey(value)} // Fixes console warings, probably some reference field breaks === comparison
      filterOptions={filterOptionsFunction(regex, enabled)} // Filter function closes over regex
      options={flattenSupersessionsAsParts(partPaths)} // Adding supersessions here to parts taken from product provider
      noOptionsText={enabled ? 'Nothing found' : `Please enter more than ${minimumSearchTermLength} characters`}
      onChange={(e, { part: { id }, pathHash, partNumber }) => {
        history.push(
          makePartUrl(
            productId,
            id,
            partNumber ? { group: pathHash, supersession: partNumber, modelType } : { group: pathHash, modelType }
          )
        );
        e.stopPropagation();
        closeSearch();
      }}
      onClick={(e) => e.stopPropagation()}
      onInputChange={(_, value) => setQuery(value)} // Connect Autocomplete value with react state
      renderInput={renderInputWrapper(closeSearch)}
      PopperComponent={popperComponent} // Popper required to target the list item
      renderOption={(props, option) => (
        <SearchOption key={uniqueKey(option)} props={props} searchablePart={option} regex={regex} />
      )}
      id="search-input"
    />
  );
}

const renderInputWrapper = (closeSearch: () => void) =>
  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <StyledTextField
        {...params}
        focused
        margin="normal"
        onClick={(e) => e.stopPropagation()}
        placeholder="Search for a part name or a part number"
        InputProps={{
          ...params.InputProps,
          className: 'override-class',
          autoFocus: true,
          startAdornment: <SearchIcon onClick={closeSearch} />,
        }}
      />
    );
  };

const flattenSupersessionsAsParts = (parts: PartPathWithHash[] = []) =>
  parts
    .map((p) => {
      const partPathWithHash = p as SearchablePart;
      const supersessionEntries = partPathWithHash.part.supersessions?.map((s) => ({ ...p, ...s }));
      return [partPathWithHash, ...(supersessionEntries || [])];
    })
    .flat();

const StyledTextField = styled(TextField)`
  width: 100%;
  margin: 0 !important;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.56);
  border-radius: 0;

  input::placeholder {
    opacity: 1;
    font-style: italic;
  }

  .override-class .MuiAutocomplete-endAdornment {
    display: none !important;
  }

  .override-class .MuiAutocomplete-input {
    color: ${({ theme }) => theme.palette.secondary.main} !important;
  }

  .override-class .MuiAutocomplete-input::placeholder {
    color: hsl(0, 0%, 100%) !important;
  }
`;

function SearchIcon({ onClick }: any) {
  return (
    <InputAdornment position="start" onClick={onClick}>
      <WhiteIcon icon={faMagnifyingGlass} size="lg" />
    </InputAdornment>
  );
}

const WhiteIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
`;
