import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { styled } from '@mui/material';
import MUIBox from '@mui/material/Box';
import MUIFormControl from '@mui/material/FormControl';
import MUITypography from '@mui/material/Typography';
import { Order } from '@samsonvt/shared-types/orderLambda';
import { SearchField } from 'src/components/SearchField';
import { useStickHeaderWithIntersectionObserver } from 'src/hooks/useStickHeaderWithIntersectionObserver';
import { ExportCsvButton } from 'src/pages/OrdersPages/ExportCsvButton';
import { MagnifyingGlassSearchIcon, StickyHeader } from 'src/pages/OrdersPages/styles';

export interface MobileMyOrdersTableHeaderProps {
  orders: Order[];
  searchPhrase: string;
  setSearchPhrase: (searchPhrase: string) => void;
}

export function MobileMyOrdersTableHeader({ searchPhrase, setSearchPhrase, orders }: MobileMyOrdersTableHeaderProps) {
  const isHeaderInNormalPosition = useStickHeaderWithIntersectionObserver();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(e.target.value);
  };
  return (
    <StickyHeader id="sticky-header" isHeaderInNormalPosition={isHeaderInNormalPosition}>
      <MUITypography>Sort orders by:</MUITypography>
      <TableControls>
        <MobileSearchField
          InputProps={{ endAdornment: <MagnifyingGlassSearchIcon icon={faMagnifyingGlass} /> }}
          placeholder="Search for a confirmation number or company name"
          value={searchPhrase}
          onChange={handleSearch}
          data-testid="my-orders-orders-search"
          sx={{ maxWidth: '120px', marginLeft: '2rem' }}
        />
        <ExportCsvButton orders={orders} />
      </TableControls>
    </StickyHeader>
  );
}

const MyOrdersHeader = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`;
MyOrdersHeader.defaultProps = { variant: 'h5' };

const MobileSearchField = styled(SearchField)`
  margin-left: 0 !important;
`;

const TableControls = styled(MUIBox)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const CheckedText = styled(MUITypography)`
  font-weight: bold;
  padding-left: 1rem;
`;
CheckedText.defaultProps = { variant: 'body2' };

const OrderFilterSelect = styled(MUIFormControl)`
  min-width: 120px;
  width: 150px;

  & .MuiSelect-select {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

OrderFilterSelect.defaultProps = {
  variant: 'filled',
  size: 'small',
};
