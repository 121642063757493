import { CognitoUserSession } from 'amazon-cognito-identity-js';

const roleAttributeName = 'custom:vtaas-role';
const noAccessRole = 'noaccess';

export const isAuthorised = (session: CognitoUserSession | null) => {
  const token = session && session.getIdToken();

  return token && token.payload[roleAttributeName] !== noAccessRole;
};

// REACT_APP_COGNITO_DOMAIN env var used for developer envs userpools
export const cognitoDomain =
  process.env.REACT_APP_COGNITO_DOMAIN ||
  `https://auth.${process.env.REACT_APP_STAGE}.${process.env.REACT_APP_BASE_DOMAIN}`;
