import { Icon } from 'src/components/Icon';
import {
  StyledSlider,
  PlaybackTitle,
  PlaybackControlsLabelContainer,
  PlaybackControlsWrapper,
  PlayButton,
} from './styles';

interface IProps {
  time: number;
  isPaused: boolean;
  pause: () => void;
  play: () => void;
  updateTime: (time: number) => void;
  duration: number;
  showPauseButton: boolean;
}

function PlayB({ action }: { action: () => void }) {
  return (
    <PlayButton onClick={action} data-testid="play-animation-button">
      <Icon name="play" />
    </PlayButton>
  );
}

function PauseB({ action }: { action: () => void }) {
  return (
    <PlayButton onClick={action} data-testid="pause-animation-button">
      <Icon name="pause" />
    </PlayButton>
  );
}

export function PlaybackControls({ time, isPaused, pause, play, updateTime, duration, showPauseButton }: IProps) {
  
  if (duration === 0) return null;

  return (
    <PlaybackControlsWrapper data-testid="playback-controls">
      {showPauseButton && (isPaused ? <PlayB action={play} /> : <PauseB action={pause} />)}
      <PlaybackControlsLabelContainer>
        <PlaybackTitle>{!showPauseButton && 'Part Distance'}</PlaybackTitle>
        <StyledSlider
          data-testid="playback-scrubber"
          value={time}
          min={0}
          max={duration}
          step={0.01}
          onChange={
            (_: object, value: number | number[], _activeThumb: number) => updateTime(value as number) // number[] is for range sliders
          }
        />
      </PlaybackControlsLabelContainer>
    </PlaybackControlsWrapper>
  );
}
