import { endpoints, getUrl } from 'src/services/api';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ShoppingCartItem } from '@samsonvt/shared-types/shoppingCart';
import { isFeatureSupported } from 'src/Feature';
import { useUser } from '../User';
import { useTenant } from "../Tenant";

export const shoppingCartQueryKey = ['shoppingCart'];

export default () => {
  const { user } = useUser();
  const { enabledFeatures } = useTenant();
  const isCartSuppressed = !isFeatureSupported('shopping-cart', enabledFeatures);
  const { token } = axios.CancelToken.source();
  const shoppingCartQuery = async (): Promise<ShoppingCartItem[]> => getUrl(endpoints.shoppingCart, token);

  return useQuery(shoppingCartQueryKey, shoppingCartQuery, {
    initialData: [],
    enabled: !!user && !isCartSuppressed,
  });
};
