import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { styled } from '@mui/material';
import MUIBox from '@mui/material/Box';
import MUIFormControl from '@mui/material/FormControl';
import MUITypography from '@mui/material/Typography';
import { Order } from '@samsonvt/shared-types/orderLambda';
import { SearchField } from 'src/components/SearchField';
import { useStickHeaderWithIntersectionObserver } from 'src/hooks/useStickHeaderWithIntersectionObserver';
import { ExportCsvButton } from '../../ExportCsvButton';
import { MagnifyingGlassSearchIcon, StickyHeader } from '../../styles';

export interface MyOrdersControlsProps {
  searchPhrase: string;
  displayOrders: Order[];
  setSearchPhrase: (searchPhrase: string) => void;
}

export function MyOrdersTableHeader({ searchPhrase, displayOrders, setSearchPhrase }: MyOrdersControlsProps) {
  const isHeaderInNormalPosition = useStickHeaderWithIntersectionObserver();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(e.target.value);
  };

  return (
    <StickyHeader id="sticky-header" isHeaderInNormalPosition={isHeaderInNormalPosition}>
      <TableControls>
        <SearchField
          InputProps={{ endAdornment: <MagnifyingGlassSearchIcon icon={faMagnifyingGlass} /> }}
          placeholder="Search for a confirmation number or company name"
          value={searchPhrase}
          onChange={handleSearch}
          data-testid="received-orders-search"
        />
        <RightSection>
          <ExportCsvButton orders={displayOrders} />
        </RightSection>
      </TableControls>
    </StickyHeader>
  );
}

const ReceivedOrdersHeader = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`;
ReceivedOrdersHeader.defaultProps = { variant: 'h5' };

const TableControls = styled(MUIBox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckedText = styled(MUITypography)`
  font-weight: bold;
  padding-left: 1rem;
`;
CheckedText.defaultProps = { variant: 'body2' };

const RightSection = styled(MUIBox)`
  display: flex;
  flex-direction: row;
  margin: 1rem;
  height: calc(1.4375em + 2rem); // Height of the select field
`;

const OrderFilterSelect = styled(MUIFormControl)`
  width: 35%;
  margin: 1rem;
  min-width: 190px;

  & .MuiSelect-select {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

OrderFilterSelect.defaultProps = {
  variant: 'filled',
  size: 'small',
};
