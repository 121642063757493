import { styled } from '@mui/material';
import { device } from 'src/constants/mediaQueries';
import { clientLogoWidth } from 'src/constants/sizes';
import { useUser } from 'src/providers/User';
import samsonLogoUrl from 'src/assets/logo-default.svg';

export function Logo() {
  const {
    logoUrl,
    userDetails: { name },
  } = useUser();
  return <StyledClientLogo alt={name} src={logoUrl || samsonLogoUrl} />;
}

const StyledClientLogo = styled('img')`
  display: block;
  width: auto;
  max-height: 3.125rem;
  max-width: ${clientLogoWidth};
  @media ${device.mobileOrTablet} {
    max-width: 8rem;
  }
`;
