import { Size } from '@react-three/fiber';
import { Camera, Object3D, Raycaster, Intersection } from 'three';
import { isCanvasElement } from './domUtils';

interface Offset {
  left: number;
  top: number;
}

export interface PointerRaycaster {
  intersectObjects: (meshes: Object3D[], e: MouseEvent) => Intersection[];
}

const recursive = true;

const calculateCameraOffset = (e: MouseEvent, size: Size, canvasOffset: Offset) => {
  const x = ((e.x - canvasOffset.left) / size.width) * 2 - 1;
  const y = -((e.y - canvasOffset.top) / size.height) * 2 + 1;

  return { x, y };
};

export const makeRaycaster = (camera: Camera, size: Size, canvasOffset: Offset): PointerRaycaster => {
  const raycaster = new Raycaster();

  return {
    intersectObjects: (meshes: Object3D[], e: MouseEvent) => {
      if (!(meshes.length && isCanvasElement(e.target))) {
        return [];
      }

      const offset = calculateCameraOffset(e, size, canvasOffset);
      raycaster.setFromCamera(offset, camera);

      return raycaster.intersectObjects(meshes, recursive);
    },
  };
};
