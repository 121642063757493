import type { ProductFeature } from '@samsonvt/shared-types/productsTable';
import { useLocation } from 'react-router-dom';
import { ScreenName } from 'src/App';

export const groupKey = 'group';
export const meshKey = 'mesh';
export type DefaultBuyableQuantityQueryParamKey = 'defaultBuyableQuantity';
const partsTab: ProductFeature = 'parts-catalogue';
const productLibraryPath: ScreenName = 'product-library';

type Keys = 'group' | 'mesh' | 'supersession' | 'modelType';

type Params = {
  [key in Keys]?: string;
};

export const useQueryStringValue = (key: Keys) => {
  const params = new URLSearchParams(useLocation()?.search);
  const value = params?.get(key);
  if (value) return decodeURIComponent(value);
};

export const makePartUrl = (productId: string, partId: string, params: Params = {}) => {
  let unencodedURI = ['', productLibraryPath, productId, partsTab, partId];

  if (params.modelType === 'SPLIT-PARENT') {
    unencodedURI = ['', productLibraryPath, `${productId}_${partId}`, partsTab, ''];
  }

  const url = unencodedURI.map(encodeURIComponent).join('/');
  const querystring = new URLSearchParams(trimObject(params)).toString();

  return querystring ? `${url}?${querystring}` : url;
};

const trimObject = (obj: {}) =>
  // eslint-disable-next-line no-return-assign
  Object.entries(obj).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {} as { [key: string]: any });

export const appendDefaultBuyableQuantityToPartUrl = (urlToAppendTo: string, defaultBuyableQuantity: number) => {
  const urlUtility = new URL(urlToAppendTo, window.location.origin);
  urlUtility.searchParams.set('defaultBuyableQuantity', defaultBuyableQuantity.toString());
  return `${urlUtility.pathname}${urlUtility.search}`;
};
