import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import {
  getFilterFunc,
  getOrderedSortingFunc,
  getSearchFunc,
  SortDirection,
  SortOption,
  sortOptions,
  ViewFilterOption,
  viewFilterOptions,
} from 'src/pages/OrdersPages/filters';
import { getOrders } from 'src/services/api';

export function useSortedOrders() {
  const [filterBy, setFilterBy] = useState<ViewFilterOption>(viewFilterOptions.ALL_ORDERS);
  const [sortBy, setSortBy] = useState<SortOption>(sortOptions.DATE);
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [searchPhrase, setSearchPhrase] = useState<string>('');

  const receivedOrdersQuery = () => getOrders();

  const {
    isLoading: isLoadingOrders,
    data: allOrders = [],
    isError: isErrorLoadingOrders,
  } = useQuery(['receivedOrdersList'], receivedOrdersQuery);

  const sortingPredicate = getOrderedSortingFunc(sortBy, sortDirection);
  const viewFilterPredicate = getFilterFunc(filterBy);
  const searchPredicate = getSearchFunc(searchPhrase);

  const displayOrders = [...allOrders].filter(viewFilterPredicate).filter(searchPredicate).sort(sortingPredicate);

  const isTheTableEmpty = !isLoadingOrders && displayOrders.length === 0;

  /**
   * Sets the sorting direction and the sorting option
   */
  const setSorting = (selectedSortBy: SortOption) => {
    const isSameSortBy = selectedSortBy === sortBy;
    const newSortDirection = isSameSortBy && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newSortDirection);
    setSortBy(selectedSortBy);
  };

  const currentSortOption = { sortBy, sortDirection };

  return {
    displayOrders,
    isErrorLoadingOrders,
    isLoadingOrders,
    isTheTableEmpty,
    searchPhrase,
    setSorting,
    setViewFilter: setFilterBy,
    setSearchPhrase,
    currentViewFilter: filterBy,
    currentSortOption,
  };
}
