import MUIDrawer from '@mui/material/SwipeableDrawer';
import { styled } from '@mui/material';
import type { History } from 'history';

import { useState, type Dispatch, type SetStateAction } from 'react';
import type { ProductListAPIResponse } from '@samsonvt/shared-types/productLambda';

import { ISO4217 } from '@samsonvt/shared-types/orderLambda';
import { MutateAccountDetails } from 'src/providers/User/userDetails';
import MobileProductMenuList from './MobileProductMenuList';
import MobileProductMenuHeader from './MobileProductMenuHeader';
import MobileProductMenuFooter from './MobileProductMenuFooter';
import MobileCurrencyMenu from './MobileCurrencyMenu';

interface MobileProductMenuProps {
  openMobileProductMenu: boolean;
  setOpenMobileProductMenu: Dispatch<SetStateAction<boolean>>;
  history: History<unknown>;
  products: ProductListAPIResponse;
  currentProductName: string | undefined;
  logout: () => Promise<void>;
  currency: ISO4217;
  currencies: ISO4217[] | undefined;
  mutateCurrencyProps: MutateAccountDetails;
}

export default function MobileProductMenu({
  openMobileProductMenu,
  setOpenMobileProductMenu,
  history,
  products,
  currentProductName,
  logout,
  currency,
  currencies,
  mutateCurrencyProps,
}: MobileProductMenuProps) {
  const [currencyPanelOpen, setCurrencyPanelOpen] = useState(false);
  const triggerMobileCurrencyMenu = () => setCurrencyPanelOpen((prev) => !prev);
  const triggerMobileProductMenu = () => setOpenMobileProductMenu((prev) => !prev);

  const goToLibrary = () => {
    history.push('/product-library');
    triggerMobileProductMenu();
  };

  return (
    <StyledDrawer open={openMobileProductMenu} onClose={triggerMobileProductMenu} onOpen={triggerMobileProductMenu}>
      <MenuWrapper data-testid="mobile-product-menu">
        {currencyPanelOpen && currencies ? (
          <MobileCurrencyMenu
            triggerMobileCurrencyMenu={triggerMobileCurrencyMenu}
            currencies={currencies}
            currency={currency}
            mutateCurrencyProps={mutateCurrencyProps}
          />
        ) : (
          <>
            <MobileProductMenuHeader triggerMobileProductMenu={triggerMobileProductMenu} goToLibrary={goToLibrary} />
            <MobileProductMenuList
              products={products}
              currentProductName={currentProductName}
              history={history}
              triggerMobileProductMenu={triggerMobileProductMenu}
            />
            <MobileProductMenuFooter
              logout={logout}
              currency={currency}
              triggerMobileCurrencyMenu={triggerMobileCurrencyMenu}
              currencies={currencies}
            />
          </>
        )}
      </MenuWrapper>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(MUIDrawer)`
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

StyledDrawer.defaultProps = {
  anchor: 'left',
  color: 'secondary',
  PaperProps: {
    sx: {
      background: ({ palette }) => palette.primary.dark,
      boxSizing: 'border-box',
      overflow: 'visible',
      width: 320,
    },
  },
  swipeAreaWidth: 0,
};
const MenuWrapper = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
