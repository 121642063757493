import { styled } from '@mui/material';
import MUITableCell from '@mui/material/TableCell';
import MUITableRow from '@mui/material/TableRow';

export const TableHeader = styled(MUITableCell)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.grey[900]};
  border: none;
  white-space: nowrap;
  height: 56px;
  cursor: pointer;
`;

TableHeader.defaultProps = { align: 'left' };

export const TableData = styled(MUITableCell)`
  color: ${({ theme }) => theme.palette.grey[900]};
  border: none;
  text-align: left;
`;

export const TableDataRow = styled(MUITableRow)`
  background-color: ${({ theme }) => theme.palette.common.white};
  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
  &:last-child td,
  &:last-child th {
    margin: 10px;
  }
`;

// for usage see DiscountManagement.tsx or ReceivedOrdersTable.tsx

/* <MUITable>
<MUITableHead>
  <MUITableRow>
    <TableHeader>Name of the discount</TableHeader>
    <TableHeader>Percentage of the discount</TableHeader>
    <TableHeader sx={{ width: '20%' }}> </TableHeader>
  </MUITableRow>
</MUITableHead>
<MUITableBody>
  {[{ key: 'hello', discountPercentage: 50, name: 'discount' }].map((scheme: any) => (
    <TableDataRow key={scheme.key}>
      <TableData>{scheme.name}</TableData>
      <TableData>{scheme.discountPercentage}</TableData>
      <TableData sx={{ textAlign: 'center' }}>
        <DeleteDiscountButton>
          <TrashIcon icon={faTrash} />
          Delete discount
        </DeleteDiscountButton>
      </TableData>
    </TableDataRow>
  ))}
</MUITableBody>
</MUITable> */
