import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Toast } from 'src/components/Toast';
import { IFile, IProductFile } from 'src/components/Uploader';
import { error as logError } from 'src/services/log';
import { UploadProgressModal } from './UploadProgressModal';

interface IProps {
  filesForUpload: IProductFile[];
  cleanFiles: () => void;
}

export type FileUploadStatus = {
  percentage: number;
  isSuccess?: boolean;
  isError?: boolean;
};

export type FileUploadStatusMap = Map<string, FileUploadStatus>;

export function UploadInProgress({ filesForUpload, cleanFiles }: IProps): JSX.Element {
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [filesUploadStatus, setFilesUploadStatus] = useState<FileUploadStatusMap>(new Map());

  const updateFilesProgress = useCallback((fileName: string, status: Partial<FileUploadStatus>) => {
    setFilesUploadStatus((prev) => {
      const newMap = new Map(prev);
      const newStatus = { ...newMap.get(fileName), ...status } as FileUploadStatus;
      newMap.set(fileName, newStatus);
      return newMap;
    });
  }, []);

  useEffect(() => {
    const unload = (event: BeforeUnloadEvent) => {
      if (!isError && !isSuccess) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [isError, isSuccess]);

  useEffect(() => {
    // const timeStarted = new Date();
    setIsModalOpen(true);
    const uploadFilesPromises = filesForUpload.map(async (file: IFile) => {
      if (file.presignedUrl) {
        try {
          return await axios
            .put(file.presignedUrl.url, file, {
              onUploadProgress: (event) => {
                if (event?.loaded && event?.total) {
                  // Use this to calculate upload speed and time remaining
                  // const timeElapsed = new Date().getTime() - timeStarted.getTime(); // Assuming that timeStarted is a Date Object
                  // const uploadSpeed = event.loaded / (timeElapsed / 1000); // Upload speed in second
                  // const secondsRemaining = (event.total - event.loaded) / uploadSpeed;
                  const percentage = ((event.loaded / event.total) * 100).toFixed(2);

                  updateFilesProgress(file.name, { percentage: Number(percentage) });
                }
              }
            })
            .then(() => {
              updateFilesProgress(file.name, { isSuccess: true });
            });
        } catch (error) {
          updateFilesProgress(file.name, { isError: true });
          throw error;
        }
      }
    });

    Promise.all(uploadFilesPromises)
      .then(() => {
        setIsSuccess(true);
      })
      .catch((err) => {
        logError(err, 'Unable to upload file');
        setIsError(true);
      });
  }, [filesForUpload, updateFilesProgress]);

  return (
    <>
      <UploadProgressModal
        isOpen={isModalOpen}
        close={() => {
          cleanFiles();
          setIsModalOpen(false);
        }}
        filesUploadStatus={filesUploadStatus}
      />

      <Toast
        dependency={isError}
        severity="error"
        title="Upload error"
        message={`There has been a problem uploading your ${filesForUpload.length > 1 ? ' files' : ' file'}`}
      />
      <Toast
        dependency={isSuccess}
        severity="success"
        title="Successfully uploaded"
        message={
          filesForUpload.length > 1
            ? `${filesForUpload.length} files were successfully uploaded`
            : '1 File was successfully uploaded'
        }
      />
    </>
  );
}
