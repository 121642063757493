import MUIList from '@mui/material/List';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { RadioGroup, Typography, styled, FormControl } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISO4217 } from '@samsonvt/shared-types/orderLambda';
import { MutateAccountDetails } from 'src/providers/User/userDetails';
import DropdownItem from '../Header/CurrencySwitcher/DropdownItem';
import { LoadingSpinner } from '../Loading';

interface MobileCurrencyMenuProps {
  triggerMobileCurrencyMenu: () => void;
  currencies: ISO4217[];
  currency: ISO4217;
  mutateCurrencyProps: MutateAccountDetails;
}

export default function MobileCurrencyMenu({
  triggerMobileCurrencyMenu,
  currencies,
  currency: currentlySelectedCurrency,
  mutateCurrencyProps,
}: MobileCurrencyMenuProps) {
  const { applyUserDetails, isApplyUserLoading } = mutateCurrencyProps;

  const onClick = (currency: ISO4217) => () => applyUserDetails({ currency });
  return (
    <CurrencyPage>
      <CurrencyPageHeader>
        <BackButton icon={faChevronLeft} onClick={triggerMobileCurrencyMenu} />
        <CurrencyPageTitle>Currency</CurrencyPageTitle>
      </CurrencyPageHeader>
      {isApplyUserLoading ? (
        <CurrencyLoadingSpinner>
          <LoadingSpinner />
        </CurrencyLoadingSpinner>
      ) : (
        <CurrencyPageList>
          <FormControl sx={{flexGrow: 1}}>
            <RadioGroup value={currentlySelectedCurrency}>
              {currencies?.map((tenantDefinedCurrency) => (
                <DropdownItem
                  key={tenantDefinedCurrency}
                  onClick={onClick(tenantDefinedCurrency)}
                  currency={tenantDefinedCurrency}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </CurrencyPageList>
      )}
    </CurrencyPage>
  );
}

const CurrencyPage = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CurrencyLoadingSpinner = styled('div')`
  margin: auto;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const CurrencyPageHeader = styled('div')`
  color: white;
  background-color: ${({ theme }) => theme.palette.sidebarBackground.main};
  display: flex;
  align-items: flex-end;
  min-height: 100px;
`;

const CurrencyPageTitle = styled(Typography)`
  color: white;
  font-size: 1.5rem;
  margin-left: 60px;
  margin-bottom: 0.5rem;
`;

const CurrencyPageList = styled(MUIList)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  display: flex;
`;

export const BackButton = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  margin: 1rem 1rem;
`;
