import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MUITypography from '@mui/material/Typography';
import { useContext } from 'react';
import { StandardBreadcrumbs } from 'src/components/Breadcrumbs/StandardBreadcrumbs';
import { EmptyStyledButton } from 'src/components/Button';
import { LoadingSpinner } from 'src/components/Loading';
import { FullscreenLoadingSpinner } from 'src/components/Loading/FullscreenLoadingSpinner';
import { Toast } from 'src/components/Toast';
import useTranslation from 'src/hooks/useTranslation';
import {
  ExportButtonContainer,
  OrderDetailsButtonsContainer,
  OrderDetailsData,
  OrderDetailsDataCell,
  OrderDetailsDataHeader,
  OrderDetailsDataRow,
  OrderDetailsDivider,
  OrderDetailsHeader,
  OrderDetailsLeftPanel,
  OrderDetailsMainContent,
  OrderDetailsPanel,
  OrderDetailsPanelTitle,
  OrderDetailsSection,
  OrderDetailsTitleContainer
} from 'src/pages/OrdersPages/ReceivedOrdersPage/OrderDetailsPage/styles';
import { useOrderDetails } from 'src/pages/OrdersPages/ReceivedOrdersPage/OrderDetailsPage/useOrderDetails';
import { ExportPdfButton } from 'src/pages/OrdersPages/ExportPdfButton';
import { TenantContext } from 'src/providers/Tenant';
import { formatDateWithTime } from 'src/services/formatDate';
import DesktopMyOrderPartsList from './DesktopMyOrderPartsList';

export default function DesktopMyOrderDetails() {
  const {
    placeHolderOrder,
    isLoadingOrderDetails,
    isLoadingResendEmailToCustomer,
    isSuccessResendEmailToCustomer,
    isErrorOrderDetails,
    isErrorResendEmailToCustomer,
    orderDetails,
    sendMailToCustomer
  } = useOrderDetails();

  const order = orderDetails ?? placeHolderOrder;

  const {
    orderId,
    eCommerceOrderName,
    firstName,
    lastName,
    companyName,
    telephone,
    customerReference,
    messageToSeller,
    items,
    createdByEmail: email,
    timestamp
  } = order;

  const { enabledFeatures } = useContext(TenantContext);
  const isShopifyEnabledAccount = enabledFeatures.includes('ecommerce');

  const { country, city, address1, address2, postalCode } = order.address;

  const customerAccountFieldValue = order['vtaas-ui:checkout:customerAccountField'];
  const customerAccountFieldLabel = `${useTranslation('vtaas-ui:checkout:customerAccountField', 'Your account')}:`;

  const { totalPrice } = order;

  return (
    <>
      {isLoadingOrderDetails ? (
        <FullscreenLoadingSpinner />
      ) : (
        <>
          <StandardBreadcrumbs
            current={{ name: eCommerceOrderName || orderId }}
            parents={[
              { name: 'My account', url: '/my-account' },
              { id: 'My orders', name: 'My orders', url: '/my-orders' }
            ]}
            sx={{ margin: '1rem 0 2rem 0' }}
          />
          <OrderDetailsHeader>
            <OrderDetailsTitleContainer>
              <MUITypography variant="h5">Order details for {eCommerceOrderName || orderId}</MUITypography>
              <MUITypography variant="body1">Order received: {formatDateWithTime(timestamp)} </MUITypography>
            </OrderDetailsTitleContainer>
            <OrderDetailsButtonsContainer>
              {isShopifyEnabledAccount ? null : (
                <EmptyStyledButton
                  startIcon={isLoadingResendEmailToCustomer ? null : <FontAwesomeIcon icon={faEnvelope} />}
                  onClick={() => sendMailToCustomer(orderId)}
                  sx={{ margin: 0, marginRight: '1rem' }}
                >
                  {isLoadingResendEmailToCustomer ? <LoadingSpinner width="100" height="100" /> : 'Resend me the order'}
                </EmptyStyledButton>
              )}
              <ExportButtonContainer>
                <ExportPdfButton order={order} />
              </ExportButtonContainer>
            </OrderDetailsButtonsContainer>
          </OrderDetailsHeader>
          <OrderDetailsMainContent>
            <OrderDetailsLeftPanel>
              <OrderDetailsSection>
                <OrderDetailsPanelTitle>Customer details</OrderDetailsPanelTitle>
                <OrderDetailsData>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>First name:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{firstName}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Last name:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{lastName}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Company name:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{companyName}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Telephone:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{telephone}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Email:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{email}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                </OrderDetailsData>
              </OrderDetailsSection>
              <OrderDetailsDivider />
              <OrderDetailsSection>
                <OrderDetailsPanelTitle>Delivery address</OrderDetailsPanelTitle>
                <OrderDetailsData>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Building name / number:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{address1}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Street address:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{address2}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Town / City:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{city}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Postcode:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{postalCode}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Country:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{country}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                </OrderDetailsData>
              </OrderDetailsSection>
              <OrderDetailsDivider />
              <OrderDetailsSection>
                <OrderDetailsData>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Your reference:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{customerReference}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>{customerAccountFieldLabel}</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{customerAccountFieldValue}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Message:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell sx={{ fontWeight: 'normal' }}>{messageToSeller}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                </OrderDetailsData>
              </OrderDetailsSection>
            </OrderDetailsLeftPanel>
            <OrderDetailsPanel>
              <OrderDetailsSection sx={{ height: '100%' }}>
                <OrderDetailsPanelTitle>Parts ordered</OrderDetailsPanelTitle>
                {items.length !== 0 && !isErrorOrderDetails && (
                  <DesktopMyOrderPartsList items={items} totalPrice={totalPrice} />
                )}
              </OrderDetailsSection>
            </OrderDetailsPanel>
          </OrderDetailsMainContent>
        </>
      )}
      <Toast
        dependency={isErrorOrderDetails}
        severity="error"
        title="Error"
        message="There has been an error loading this order"
      />
      <Toast dependency={isSuccessResendEmailToCustomer} severity="success" title="Success" message="Email resent!" />
      <Toast
        dependency={isErrorResendEmailToCustomer}
        severity="error"
        title="Error"
        message="There has been an error resending the email!"
      />
    </>
  );
}
