import { PORComponent, PORProps } from 'src/components/POR';
import type { DiscountedPartPrice, PartPrice } from '@samsonvt/shared-types/partsTable';
import { useUser } from 'src/providers/User';
import { isFeatureSupported } from 'src/Feature';
import { AvailableISO639ForTranslation } from '@samsonvt/shared-types/accountsTable';
import { ISO4217 } from '@samsonvt/shared-types/orderLambda';
import { useTenant } from 'src/providers/Tenant';

const currencyToCountryMap: Record<ISO4217, string> = {
  EUR: 'European Union',
  GBP: 'United Kingdom',
  USD: 'United States',
};

const currencyToLocaleMap: Record<ISO4217, AvailableISO639ForTranslation> = {
  EUR: 'de-de',
  GBP: 'en-gb',
  USD: 'en-us',
};
const getLocaleFromCurrency = (currency: ISO4217) => currencyToLocaleMap[currency];

export function getCurrencyName(currencyCode: ISO4217 | undefined) {
  if (!currencyCode) {
    return 'Great British Pound';
  }
  return currencyToCountryMap[currencyCode];
}

export function getCurrencySymbol(currencyCode: ISO4217 | undefined) {
  if (currencyCode) {
    const currencySymbol = Intl.NumberFormat(getLocaleFromCurrency(currencyCode), {
      style: 'currency',
      currency: currencyCode,
    })
      .formatToParts(0)
      .find((part) => part.type === 'currency');

    return currencySymbol?.value;
  }
  return '£';
}

export const formatCurrency = ({ price, currency: currencyToUse, ...porProps }: FormattedPriceParams) => {
  const currency = currencyToUse ?? 'GBP';

  if (price !== undefined) {
    if (price === 'por') {
      return <PORComponent {...porProps} />;
    }

    if (Number.isNaN(price)) {
      return `${getCurrencySymbol(currencyToUse)}--.--`;
    }

    const formatter = Intl.NumberFormat(getLocaleFromCurrency(currency), {
      style: 'currency',
      currency,
    });

    return formatter.format(price);
  }
  return `${getCurrencySymbol(currencyToUse)}--.--`;
};

type FormattedPriceParams = {
  price: PartPrice;
  currency?: ISO4217;
} & PORProps;

export default function useFormattedPrice({ price, currency: currencyOverride, ...porProps }: FormattedPriceParams) {
  const { currencies } = useTenant();
  const {
    enabledFeatures,
    preferredCurrency: tenantCurrency,
    userDetails: { currency: userCurrency },
  } = useUser();
  const currencyToUse =
    currencyOverride ||
    determineCurrencyToUse({ currency: userCurrency, preferredCurrency: tenantCurrency, currencies });
  return !isFeatureSupported('shopping-cart', enabledFeatures)
    ? ''
    : formatCurrency({ ...porProps, price, currency: currencyToUse });
}

export const calculateDiscountedPrice = (
  price: NonNullable<PartPrice>,
  discountPercentage?: number
): DiscountedPartPrice => {
  const multiplier = discountPercentage ? (100 - discountPercentage) / 100 : 1;
  if (typeof price === 'number') return price * multiplier;
  return price;
};

interface DetermineCurrencyContext {
  currency: ISO4217 | undefined;
  preferredCurrency: ISO4217 | undefined;
  currencies: ISO4217[] | undefined;
}

export const determineCurrencyToUse = ({
  currency: userCurrency,
  preferredCurrency: tenantCurrency,
  currencies,
}: DetermineCurrencyContext) => {
  // User currency always has precedence over tenant currency only if multi-currency is present and the userCurrency is allowed
  const shouldUseUserCurrency = currencies && userCurrency && currencies.includes(userCurrency);
  return shouldUseUserCurrency ? userCurrency : tenantCurrency || 'GBP';
};
