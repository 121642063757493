import { styled } from '@mui/material';
import { StyledMUIButtonLink } from 'src/components/Button';

export const StepButton = styled(StyledMUIButtonLink, { shouldForwardProp: (prop: string) => prop !== 'hideOnTablet' })(() => ({
  lineHeight: '26px',
  margin: 0,
  flexGrow: 1,
  flexBasis: 0,
  width: 'auto',
}));
