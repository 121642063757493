import { useState } from 'react';

import { Toast, useTrigger } from 'src/components/Toast';
import { error as logError } from 'src/services/log';
import { useMediaQuery } from '@mui/material';
import { device } from 'src/constants/mediaQueries';
import {
  CreatePasswordWithTempCredentialsFormContainerProps,
  CreatePasswordWithTempCredentialsFormData,
  CreatePasswordWithTempCredentialsFormProps,
} from './types';
import { DesktopCreatePasswordWithTempCredentialsForm } from './Desktop/DesktopCreatePasswordWithTempCredentialsForm';
import { MobileCreatePasswordWithTempCredentialsForm } from './Mobile/MobileCreatePasswordWithTempCredentialsForm';

export function CreatePasswordWithTempCredentialsFormContainer({
  setLoading,
  createPasswordWithTempCredentialsQueryParams,
  createPasswordWithTempCredentials,
  triggerSuccessToast,
  history,
}: CreatePasswordWithTempCredentialsFormContainerProps) {
  const [createPasswordWithTempCredentialsFormData, setCreatePasswordWithTempCredentialsFormData] =
    useState<CreatePasswordWithTempCredentialsFormData>({
      password: '',
      oldPassword: '',
      confirmPassword: '',
    });

  const { password, confirmPassword } = createPasswordWithTempCredentialsFormData;

  const [errorToast, setErrorToast] = useTrigger();
  const [errorToastMessage, setToastErrorMessage] = useState<string | undefined>();

  const isMobile = useMediaQuery(`${device.smallLaptop} , ${device.mobileOrTablet}`); // using small laptop because the requirements text is occluded from small laptops down

  const checkPasswordsMatch = () => {
    if (password && confirmPassword) {
      return password === confirmPassword;
    }
    return undefined;
  };

  const showErrorToast = (message: string) => {
    setLoading(false);
    setToastErrorMessage(message);
    setErrorToast();
  };

  const validateBeforeSending = () => {
    const error = new Error();

    if (!createPasswordWithTempCredentialsQueryParams.email) {
      error.name = 'LinkError';
      throw error;
    }
  };

  const createPasswordWithTempCredentialsAndLogin = async (tempPassword: string, newPassword: string) => {
    await createPasswordWithTempCredentials(
      createPasswordWithTempCredentialsQueryParams.email as string,
      tempPassword,
      newPassword
    );
  };

  const onButtonClick = (data: CreatePasswordWithTempCredentialsFormData) => async (e: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      validateBeforeSending();
      await createPasswordWithTempCredentialsAndLogin(data.oldPassword, data.password); // remove white spaces when copying email-generated password
      setLoading(false);
      triggerSuccessToast('Password changed', 'Your new password has been succesfully changed.');
      history.go(0); // refresh the page to make sure the cognito session is properly loaded
      history.push('/product-library');
    } catch (e: any) {
      logError(e);

      // all possible errors are listed here

      switch (e.name) {
        case 'NotAuthorizedException':
          showErrorToast('The current password given does not match the current password');
          break;

        case 'InvalidPasswordException':
          showErrorToast('The password does not meet the given requirements');
          break;

        case 'InvalidParameterException':
          showErrorToast('The password does not meet the given requirements');
          break;

        case 'LimitExceededException':
          showErrorToast('You have excedeeded the retry limit, try again in a few minutes');
          break;

        case 'LinkError':
          showErrorToast(
            'It looks like the link you are on is wrong. Please try clicking the link from your email again'
          );
          break;

        default:
          showErrorToast(e.toString());
          break;
      }
    }
  };

  const createPasswordWithTempCredentialsFormProps: CreatePasswordWithTempCredentialsFormProps = {
    error: !!errorToast,
    onButtonClick,
    createPasswordWithTempCredentialsFormData,
    setCreatePasswordWithTempCredentialsFormData,
    checkPasswordsMatch,
  };

  return (
    <>
      {isMobile ? (
        <MobileCreatePasswordWithTempCredentialsForm {...createPasswordWithTempCredentialsFormProps} />
      ) : (
        <DesktopCreatePasswordWithTempCredentialsForm {...createPasswordWithTempCredentialsFormProps} />
      )}
      <Toast
        dependency={errorToast}
        severity="error"
        title="Error resetting password"
        message={errorToastMessage || ''}
      />
    </>
  );
}
