import { styled } from '@mui/material';
import { createBrowserHistory } from 'history';
import { useEffect, useMemo, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { NotFoundPageAuthorised } from 'src/pages/NotFoundPageAuthorised';
import { ProductsPage } from 'src/pages/ProductsPage/ProductsPage';
import ShoppingCartPage from 'src/pages/ShoppingCartPage';
import { UploadPage } from 'src/pages/UploadPage';
import { WelcomePage } from 'src/pages/WelcomePage';
import { NotFoundPage, RedirectAnonUsers, RedirectAuthedUsers } from 'src/templates/ProtectedPage';
import ResetPasswordFormPage from './pages/ResetPasswordPage';
import { Toast, useTrigger } from './components/Toast';
import { Feature } from './Feature';
import DocumentTitleEffectWrapper from './hooks/DocumentTitle';
import CheckoutPage from './pages/CheckoutPage';
import ConfirmAccountPage from './pages/ConfirmAccountPage';
import { CreateAccountPage } from './pages/CreateAccountPage';
import { DiscountManagement } from './pages/DiscountManagementPage/DiscountManagement';
import InitialisationFailure from './pages/InitialisationFailure';
import { NotFoundPageUnAuthorised } from './pages/NotFoundPageUnAuthorised';
import MyOrdersPage from './pages/OrdersPages/MyOrdersPage';
import ReceivedOrdersPage from './pages/OrdersPages/ReceivedOrdersPage';
import { SendResetPasswordEmailPage } from './pages/SendResetPasswordEmailPage';
import { UserManagement } from './pages/UserManagementPage/UserManagement';
import { ResizeAppGlobalStyles } from './resizeAppHelper';
import { isProduction } from './utils/isProduction';
import AccountDetailsPage from './pages/AccountDetailsPage/AccountDetailsPage';
import CreatePasswordWithTempCredentialsFormPage from './pages/CreatePasswordWithTempCredentialsPage';
import MyAccountPage from './pages/MyAccountPage/MyAccountPage';
import AuthorizationCodePage from './pages/AuthorizationCodePage';

const Version = styled('div')`
  display: none;
`;

function initMouseflow() {
  if (isProduction()) {
    // eslint-disable-next-line no-underscore-dangle
    (window as any)._mfq = (window as any)._mfq || [];

    const mf = document.createElement('script');
    mf.type = 'text/javascript';
    mf.defer = true;
    mf.src = 'https://cdn.mouseflow.com/projects/df3f82b0-6bef-4afa-89ac-3c50c042494b.js';
    document.getElementsByTagName('head')[0].appendChild(mf);
  }
}

export function App() {
  const [successToast, setSuccessToast] = useTrigger();
  const [successToastMessage, setSuccessToastMessage] = useState<string | undefined>();
  const [successToastMessageTitle, setSuccessToastMessageTitle] = useState<string | undefined>();

  const triggerSuccessToast = (title: string, message: string) => {
    setSuccessToast();
    setSuccessToastMessageTitle(title);
    setSuccessToastMessage(message);
  };

  const memoisedBrowserHistory = useMemo(() => createBrowserHistory(), []);

  useEffect(() => {
    initMouseflow();
  }, []);

  return (
    <>
      <ResizeAppGlobalStyles />
      <Router history={memoisedBrowserHistory}>
        <DocumentTitleEffectWrapper />
        <Switch>
          <Route exact path="/">
            <RedirectAuthedUsers>
              <WelcomePage />
            </RedirectAuthedUsers>
          </Route>

          <Route exact path={absolutePath('create-account')}>
            <RedirectAuthedUsers>
              <Feature flag="self-registration" alternate={<NotFoundPageUnAuthorised />}>
                <CreateAccountPage />
              </Feature>
            </RedirectAuthedUsers>
          </Route>

          <Route exact path={absolutePath('confirm-account')}>
            <RedirectAuthedUsers>
              <Feature flag="self-registration" alternate={<NotFoundPageUnAuthorised />}>
                <ConfirmAccountPage triggerSuccessToast={triggerSuccessToast} />
              </Feature>
            </RedirectAuthedUsers>
          </Route>

          <Route exact path={absolutePath('authorization-code')}>
            <RedirectAuthedUsers>
                <AuthorizationCodePage  />
            </RedirectAuthedUsers>
          </Route>

          <Route exact path={absolutePath('send-reset-password-email')}>
            <RedirectAuthedUsers>
              <SendResetPasswordEmailPage />
            </RedirectAuthedUsers>
          </Route>

          {/* this is the temporary password flow */}
          <Route exact path={absolutePath('create-password-with-temp-credentials')}>
            <RedirectAuthedUsers>
              <CreatePasswordWithTempCredentialsFormPage triggerSuccessToast={triggerSuccessToast} />
            </RedirectAuthedUsers>
          </Route>

          <Route exact path={absolutePath('reset-password')}>
            <RedirectAuthedUsers>
              <ResetPasswordFormPage triggerSuccessToast={triggerSuccessToast} />
            </RedirectAuthedUsers>
          </Route>

          <Route path={absolutePath('account-details')}>
            <RedirectAnonUsers>
              <AccountDetailsPage />
            </RedirectAnonUsers>
          </Route>

          <Route path={absolutePath('product-library')}>
            <RedirectAnonUsers>
              <ProductsPage />
            </RedirectAnonUsers>
          </Route>

          <Route path={absolutePath('upload')}>
            <RedirectAnonUsers permissions={[['add', 'product']]}>
              <UploadPage />
            </RedirectAnonUsers>
          </Route>

          <Route path={absolutePath('user-management')}>
            <RedirectAnonUsers permissions={[['list', 'user']]}>
              <UserManagement />
            </RedirectAnonUsers>
          </Route>

          <Route path={absolutePath('discount-management')}>
            <RedirectAnonUsers
              permissions={[
                ['list', 'discountScheme'],
                ['add', 'discountScheme'],
                ['delete', 'discountScheme'],
                ['update', 'discountScheme'],
              ]}
            >
              <DiscountManagement />
            </RedirectAnonUsers>
          </Route>

          <Route path={absolutePath('cart')}>
            <RedirectAnonUsers>
              <Feature flag="shopping-cart" alternate={<NotFoundPageAuthorised />}>
                <ShoppingCartPage />
              </Feature>
            </RedirectAnonUsers>
          </Route>

          <Route path={absolutePath('checkout')}>
            <RedirectAnonUsers>
              <Feature flag="shopping-cart" alternate={<NotFoundPageAuthorised />}>
                <CheckoutPage />
              </Feature>
            </RedirectAnonUsers>
          </Route>

          <Route path={absolutePath('received-orders')}>
            <RedirectAnonUsers
              permissions={[
                ['list', 'order.history'],
                ['update', 'order.history'],
                ['view', 'order.history'],
              ]}
            >
              <Feature flag="shopping-cart">
                <ReceivedOrdersPage />
              </Feature>
            </RedirectAnonUsers>
          </Route>

          <Route path={absolutePath('my-orders')}>
            <RedirectAnonUsers
              permissions={[
                ['list', 'order'],
                ['view', 'order'],
              ]}
            >
              <Feature flag="shopping-cart" alternate={<NotFoundPageAuthorised />}>
                <MyOrdersPage />
              </Feature>
            </RedirectAnonUsers>
          </Route>

          <Route path={absolutePath('my-account')}>
            <RedirectAnonUsers>
              <MyAccountPage />
            </RedirectAnonUsers>
          </Route>

          <Route exact path={absolutePath('initialisation-failed')}>
            <InitialisationFailure />
          </Route>

          <Route component={NotFoundPage} />
        </Switch>
      </Router>

      <Version className="version">{process.env.REACT_APP_VERSION_STRING}</Version>
      <Toast
        dependency={successToast}
        severity="success"
        title={successToastMessageTitle || 'Success'}
        message={successToastMessage || ''}
      />
    </>
  );
}

export type ScreenName =
  | 'product-library'
  | 'received-orders'
  | 'checkout'
  | 'settings'
  | 'initialisation-failed'
  | 'cart'
  | 'user-management'
  | 'upload'
  | 'create-account'
  | 'confirm-account'
  | 'send-reset-password-email'
  | 'reset-password'
  | 'discount-management'
  | 'my-orders'
  | 'account-details'
  | 'create-password-with-temp-credentials'
  | 'my-account'
  | 'authorization-code';

const absolutePath = (screenName: ScreenName) => `${process.env.PUBLIC_URL}/${screenName}`;
