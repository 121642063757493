import { ChangeEvent } from 'react';
import { styled } from '@mui/material';
import { WhiteTextField } from 'src/components/TextInput/WhiteTextField';
import { OptionalCheckoutLabel } from './styles';

const InputField = styled(WhiteTextField)`
  width: 100%;
`;

export interface ConfigurableCheckoutInputFieldProps {
    label: string,
    name: string,
    onChange: (e: ChangeEvent) => void,
    value: unknown,
    required?: boolean,
    error?: boolean,
}

function ConfigurableCheckoutInputField (
  { label, name, onChange, value, required, error }: ConfigurableCheckoutInputFieldProps
) {
    return (
        <InputField
            label={ required ? label : <OptionalCheckoutLabel label={label} /> }
            name={name}
            onChange={onChange}
            value={value}
            required={required}
            error={required && error}
            size='small'
            variant='filled'
            InputLabelProps={{ required: false }}
        />
    )

}

export default ConfigurableCheckoutInputField
