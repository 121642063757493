import { styled, TextFieldProps } from '@mui/material';
import { WhiteTextFieldWithoutLabel } from 'src/components/TextInput/WhiteTextFieldWithoutLabel';
import { Span } from '../styles';

export function AttributeTextField(props: TextFieldProps) {
  const { label, ...rest } = props;
  return (
    <Label>
      <Span>{label}</Span>
      <TextField size="small" {...rest} />
    </Label>
  );
}

export function DescriptionAttributeTextField(props: TextFieldProps) {
  const { label, ...rest } = props;
  return (
    <DescriptionLabel>
      {label}
      <DescriptionTextField size="small" multiline {...rest} />
    </DescriptionLabel>
  );
}

const Label = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
  opacity: ${({ disabled }: { disabled?: boolean }) => (disabled ? 0.25 : 1)};
`;

const DescriptionLabel = styled(Label)`
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const TextField = styled(WhiteTextFieldWithoutLabel)`
  width: 100%;
  input {
    font-weight: 400;
    font-size: 16px;
    text-align: right;
  }
`;

const DescriptionTextField = styled(WhiteTextFieldWithoutLabel)`
  width: 100%;
`;
