import { Auth } from 'aws-amplify';
import type {
  CreateDiscountSchemeBody,
  DiscountScheme,
  UpdateDiscountSchemeBody
} from '@samsonvt/shared-types/discountLambda';
import { ClientEditPartBody } from '@samsonvt/shared-types/editPart';
import type {
  CreateOrderRequest,
  CreateOrderResponse,
  CustomEvent,
  ECommerceCreateOrderResponse,
  ECommerceOrderRequest,
  Order
} from '@samsonvt/shared-types/orderLambda';
import {
  EditPartDetailsRequestBody,
  MatchModelNodeToPartDetailsRequestBody,
  MatchModelNodesToPartDetailsResponse,
  NewPartDetailsRequestBody,
  PartDetails,
  QueryPartsResponse
} from '@samsonvt/shared-types/partService';
import type {
  ProductAPIResponse,
  ProductListAPIResponse,
  UpdateProductBodyShape
} from '@samsonvt/shared-types/productLambda';
import type { RepairItemAPIResponse, RepairListAPIResponse } from '@samsonvt/shared-types/repairLambda';
import type { TenantDetailsAPIResponse, UserAPIResponse } from '@samsonvt/shared-types/userLambda';
import axios, { AxiosRequestConfig, AxiosResponse, CancelToken, Method } from 'axios';
import axiosRetry from 'axios-retry';
import type { IFile, IProductFile } from 'src/components/Uploader';
import type { UserRole } from 'src/pages/UserManagementPage/UserManagement';
import type { UserHeader, UserItem } from 'src/pages/UserManagementPage/UserManagementTable';
import { getMockTenantDetails } from 'src/utils/mockTenantDetails';
import { isAuthorised } from '../utils/cognito';
import { createHash } from './hash';
import { error as logError, info as logInfo } from './log';
import { getFileExtension } from './utils';

axiosRetry(axios, { retries: 3 });

interface Response {
  message?: string;
}

export const endpoints = {
  file: '/file',
  products: '/product',
  user: '/user',
  users: '/users',
  resend: '/users/invite',
  passwordPolicy: '/passwordPolicy',
  repair: '/repair',
  roles: '/roles',
  tenantDetails: `/tenantDetails?origin=${window.location.origin}`, // SVT-2096 origin header sometimes missing so we add param as a fallback
  order: '/order',
  eCommerceOrder: '/eCommerceOrder',
  discountScheme: '/discountScheme',
  shoppingCart: '/shoppingCart',
  editPart: '/editPart',
  partService: '/partService'
} as const;

export const getToken = async () => {
  try {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken();
    if (!isAuthorised(session)) {
      Auth.signOut();
      throw Error('No access');
    }
    return idToken.getJwtToken();
  } catch (err) {
    throw Error('Error getting token');
  }
};

const makeFetchOptions = (cancelToken?: CancelToken, authToken?: string): AxiosRequestConfig => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Content-Encoding': 'gzip'
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  return {
    headers,
    cancelToken
  };
};

const getUrlAnon = async (path: string, cancelToken?: CancelToken) => {
  const fetchOptions = makeFetchOptions(cancelToken);
  return getUrlWithConfig(path, fetchOptions);
};

export const getUrl = async (path: string, cancelToken?: CancelToken): Promise<any> => {
  const authToken = await getToken();
  const fetchOptions = makeFetchOptions(cancelToken, authToken);
  return getUrlWithConfig(path, fetchOptions);
};

const getUrlWithConfig = async (path: string, fetchOptions: AxiosRequestConfig): Promise<any> => {
  const url = process.env.REACT_APP_BASE_URL + path;

  try {
    const response = await axios.get(url, fetchOptions);
    return response.data;
  } catch (err) {
    if (axios.isCancel(err) && err.message) {
      logInfo(err.message, `request cancelled: ${err.message}`);
    } else {
      throw Error(`Unable to get data from ${url}`);
    }
  }
};

const patchUrl = async (path: string, body: any, cancelToken?: CancelToken): Promise<any> => {
  const jwtToken = await getToken();
  const url = process.env.REACT_APP_BASE_URL + path;
  const data = JSON.stringify(body);
  try {
    const fetchOptions = {
      method: 'PATCH' as Method,
      data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`
      },
      cancelToken
    };
    return await axios(url, fetchOptions);
  } catch (err: any) {
    let {
      response: {
        data: { message }
      }
    } = err;

    if (axios.isCancel(err)) {
      message = `request cancelled:${err.message}`;
    }

    logError(err, message || `Unable to get data from ${url}`);
    throw Error(message);
  }
};

export const putUrl = async <Request = any, Response = any>(
  path: string,
  body: Request,
  cancelToken?: CancelToken
): Promise<AxiosResponse<Response>> => {
  const jwtToken = await getToken();
  const url = process.env.REACT_APP_BASE_URL + path;
  const data = JSON.stringify(body);
  try {
    const fetchOptions = {
      method: 'PUT' as Method,
      data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`
      },
      cancelToken
    };
    return await axios(url, fetchOptions);
  } catch (err: any) {
    let {
      response: {
        data: { message }
      }
    } = err;

    if (axios.isCancel(err)) {
      message = `request cancelled:${err.message}`;
    }

    logError(err, message || `Unable to get data from ${url}`);
    throw Error(message);
  }
};

const postUrl = async <Request = any, Response = any>(
  path: string,
  body: Request,
  cancelToken?: CancelToken
): Promise<AxiosResponse<Response>> => {
  const jwtToken = await getToken();
  const url = process.env.REACT_APP_BASE_URL + path;
  const data = JSON.stringify(body);
  try {
    const fetchOptions = {
      method: 'POST' as Method,
      data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`
      },
      cancelToken
    };
    return await axios(url, fetchOptions);
  } catch (err: any) {
    const serverErrorMessage = err.response?.data?.message;
    const errorMessage = serverErrorMessage || `Unable to get data from ${url}`;
    const message = axios.isCancel(err) ? `request cancelled:${err.message}` : errorMessage;

    logError(err, message);
    throw Error(message);
  }
};

const makePresignedUrlRequest = async (
  filename: string,
  productName: string,
  productId: string,
  description: string,
  md5: string
) => {
  const prefix = 'prefix-string';
  const filetype = `model/${getFileExtension(filename)}`;

  const data = {
    prefix,
    filename,
    productName,
    productId,
    description,
    filetype,
    md5
  };

  return postUrl(endpoints.file, data);
};

const deleteUrl = async (path: string, cancelToken?: CancelToken): Promise<any> => {
  const jwtToken = await getToken();
  const fetchOptions = {
    method: 'DELETE' as Method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`
    },
    cancelToken
  };

  const url = process.env.REACT_APP_BASE_URL + path;

  try {
    const response = await axios(url, fetchOptions);
    return response.data;
  } catch (err: any) {
    const serverErrorMessage = err.response?.data?.message;
    const message = axios.isCancel(err) ? `request cancelled:${err.message}` : serverErrorMessage;
    logError(err, message);
    throw Error(message ?? `Unable to delete at ${url}`);
  }
};

const generatePresignedUrl = async (file: IProductFile) => {
  const md5 = await createHash(file);
  const response = await makePresignedUrlRequest(file.name, file.productName, file.productId, file.description, md5);
  return response;
};

const resolveSequentially = async (previousPromise: Promise<IFile | any>, file: Promise<IFile | any>) => {
  await previousPromise;
  return file;
};

export const attachPresignedUrls = async (files: IProductFile[]): Promise<Promise<IProductFile>[]> => {
  const filesPromises = files.map(async (file: IProductFile): Promise<IProductFile> => {
    const { data } = await generatePresignedUrl(file);
    if (!data.url) {
      throw Error(`Unable to get presigned URL for file ${file.name}`);
    }
    file.presignedUrl = data;
    return file;
  });
  await filesPromises.reduce(resolveSequentially, Promise.resolve());

  return filesPromises;
};

interface IPasswordPolicy {
  MinimumLength: number;
  RequireLowercase: boolean;
  RequireNumbers: boolean;
  RequireSymbols: boolean;
  RequireUppercase: boolean;
  TemporaryPasswordValidityDays: number;
}

export const getUser = (cancelToken?: CancelToken): Promise<UserAPIResponse> => getUrl(endpoints.user, cancelToken);

export const getPasswordPolicy = (cancelToken: CancelToken): Promise<IPasswordPolicy> =>
  getUrlAnon(endpoints.passwordPolicy, cancelToken);

export const getTenantDetails = (cancelToken: CancelToken): Promise<TenantDetailsAPIResponse> => {
  if (process.env.REACT_APP_DEV_TOOLS_ENABLED === 'true') {
    const params = new URLSearchParams(window.location.search);
    const mockTenantIndex = parseInt(params.get('mockTenant') ?? '0');
    return getMockTenantDetails(mockTenantIndex);
  }

  return getUrlAnon(endpoints.tenantDetails, cancelToken);
};

export const getProducts = (): Promise<ProductListAPIResponse> => getUrl(endpoints.products);

export const getProduct = (productId: string, cancelToken?: CancelToken): Promise<ProductAPIResponse> =>
  getUrl(`${endpoints.products}/${productId}`, cancelToken);

export const deleteProduct = (productId: string, cancelToken?: CancelToken): Promise<ProductAPIResponse> =>
  deleteUrl(`${endpoints.products}/${productId}`, cancelToken);

export const updateProduct = (
  productId: string,
  body: UpdateProductBodyShape,
  cancelToken?: CancelToken
): Promise<UpdateProductBodyShape> => patchUrl(`${endpoints.products}/${productId}`, body, cancelToken);

export const getRepairs = (productId: string, cancelToken?: CancelToken): Promise<RepairListAPIResponse> =>
  getUrl(`${endpoints.repair}/${productId}`, cancelToken);

// temporary work-around until api is changed to match rename of step -> section && action -> step
const mapActions = (sections: RepairItemAPIResponse['steps']) =>
  sections!.map(({ actions, ...section }) => ({
    ...section,
    steps: actions
  }));

export const getRepairDetails = async (productId: string, repairId: string, cancelToken?: CancelToken) => {
  const url = `${endpoints.repair}/${productId}/${repairId}`;
  const { steps, sections, ...repair }: RepairItemAPIResponse = await getUrl(url, cancelToken);

  return {
    ...repair,
    sections: sections || mapActions(steps) // mapping mapActions(steps) should be removed after all work instructions migrate to new structure ( see https://samsonvt.atlassian.net/browse/SVT-1493 )
  };
};

export const getAllUsers = async (): Promise<UserItem[]> => getUrl(endpoints.users);

export const getUserRoles = async (): Promise<UserRole[]> => getUrl(endpoints.roles);

export const patchUser = async (userId: string, data: object): Promise<Response> =>
  patchUrl(`${endpoints.users}/${userId}`, data);

export const deleteUser = async (userId: string): Promise<Response> => deleteUrl(`${endpoints.users}/${userId}`);

export const postUser = async (user: UserHeader) => postUrl<UserHeader, Response>(endpoints.users, user);

export const inviteUser = async (user: UserItem) =>
  postUrl<Partial<UserItem>, Response>(endpoints.resend, { id: user.email, role: user.role });

export const postOrder = async (order: CreateOrderRequest): Promise<AxiosResponse<CreateOrderResponse>> =>
  postUrl(endpoints.order, order);

export const postShopifyOrder = async (
  order: ECommerceOrderRequest
): Promise<AxiosResponse<ECommerceCreateOrderResponse>> => postUrl(endpoints.eCommerceOrder, order);

export const getOrders = async (cancelToken?: CancelToken): Promise<Order[]> => getUrl(endpoints.order, cancelToken);

export const getOrderById = async (orderId: string, cancelToken: CancelToken): Promise<Order> =>
  getUrl(`${endpoints.order}/${orderId}`, cancelToken);

export const patchOrder = async (orderId: string): Promise<any> =>
  patchUrl(`${endpoints.order}/${orderId}`, { status: 'COMPLETE' });

export const resendOrderEmailToSupplier = async (orderId: string): Promise<any> => {
  const emailEventType: CustomEvent = 'email.order.supplierDispatch';
  return postUrl(`${endpoints.order}/${orderId}/resendEmail`, { emailEventType });
};

export const resendOrderEmailToCustomer = async (orderId: string): Promise<any> => {
  const emailEventType: CustomEvent = 'email.order.customerDispatch';
  return postUrl(`${endpoints.order}/${orderId}/resendEmail`, { emailEventType });
};

export const getDiscountSchemes = async (cancelToken?: CancelToken): Promise<DiscountScheme[]> =>
  getUrl(`${endpoints.discountScheme}`, cancelToken);

export interface DiscountSchemeResponse {
  data: DiscountScheme;
}

export const postDiscountScheme = async (
  createDiscountSchemeBody: CreateDiscountSchemeBody,
  cancelToken?: CancelToken
): Promise<DiscountSchemeResponse> => postUrl(`${endpoints.discountScheme}`, createDiscountSchemeBody, cancelToken);

export const deleteDiscountScheme = async (discountSchemeKey: string, cancelToken?: CancelToken): Promise<any> =>
  deleteUrl(`${endpoints.discountScheme}/${discountSchemeKey}`, cancelToken);

export const putDiscountScheme = async (
  discountSchemeKey: string,
  updateDiscountSchemeBody: UpdateDiscountSchemeBody,
  cancelToken?: CancelToken
): Promise<DiscountSchemeResponse> =>
  putUrl(`${endpoints.discountScheme}/${discountSchemeKey}`, updateDiscountSchemeBody, cancelToken);

export interface EditPartParams {
  body: ClientEditPartBody;
  cancelToken?: CancelToken;
}

export interface AssignPartNumberToNodePutRequestParams {
  body: { partNumberToAssign: string; nodeIdToModify: string }; // naming could be better
  cancelToken?: CancelToken;
}

export const editPartDetails = async (body: EditPartDetailsRequestBody) =>
  putUrl<EditPartDetailsRequestBody, PartDetails>(`${endpoints.partService}/editPartDetails`, body);

export const createPartDetails = async (body: NewPartDetailsRequestBody) =>
  postUrl<NewPartDetailsRequestBody, PartDetails>(`${endpoints.partService}/createPartDetails`, body);

export const nodesAssign = async (body: MatchModelNodeToPartDetailsRequestBody) =>
  putUrl<MatchModelNodeToPartDetailsRequestBody, MatchModelNodesToPartDetailsResponse>(
    `${endpoints.partService}/modelNodes:match`,
    body
  );

// Debugging purpose only
export const nodesDissoc = async (body: Omit<MatchModelNodeToPartDetailsRequestBody, 'svtPartIdToMatch'>) =>
  putUrl<Omit<MatchModelNodeToPartDetailsRequestBody, 'svtPartIdToMatch'>, MatchModelNodesToPartDetailsResponse>(
    `${endpoints.partService}/unMatchModelNodes`,
    body
  );

// TODO add parts types
export const getPartDetails = async (svtPartID: string, cancelToken?: CancelToken): Promise<PartDetails> =>
  getUrl(`${endpoints.partService}/partDetails/${svtPartID}`, cancelToken);

export const getPartsAcrossWholeTenant = async (
  query: string,
  limit: number,
  cancelToken?: CancelToken
): Promise<QueryPartsResponse> =>
  getUrl(`${endpoints.partService}/partDetails?query=${query}&limit=${limit}`, cancelToken);
