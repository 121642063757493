import MUIPortal from '@mui/material/Portal';
import MUITypography from '@mui/material/Typography';
import { MatchModelNodesToPartDetailsResponse } from '@samsonvt/shared-types/partService';
import type { PartNode } from '@samsonvt/shared-types/productLambda';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import { getPartDetails } from 'src/services/api';
import { reactQueryGetErrorMessage } from 'src/utils/reactQueryGetErrorMessage';

import MUIDialog from '@mui/material/Dialog';
import { UseMutateAsyncFunction, useQuery } from '@tanstack/react-query';
import { useUser } from 'src/providers/User';
import { useTenant } from 'src/providers/Tenant';
import { LoadingSpinner } from '../Loading';
import { Toast } from '../Toast';
import { EditModal, PartDetailsForm } from './EditModal';
import { ExtractionDecisionModal } from './ExtractionDecisionModal';
import { DisassociateParams } from './MutationsHost';
import { FlexColumn } from './styles';
import { partDetailsToPartForm } from './utils';

type Props = {
  partNode: PartNode;
  handleClose: () => void;
  modalOpen: boolean;
  mutateRemovePartDetailsFromNode: UseMutateAsyncFunction<
    AxiosResponse<MatchModelNodesToPartDetailsResponse>,
    unknown,
    DisassociateParams,
    unknown
  >;
} & Omit<
  React.ComponentProps<typeof EditModal>,
  | 'svtPartIDToAssignToANode'
  | 'setSvtPartIDToAssignToANode'
  | 'userPressedContinue'
  | 'setUserPressedContinue'
  | 'isLoadingPartDetails'
  | 'isFetchingPartDetails'
  | 'fetchedPartDetails'
  | 'editedNodes'
  | 'initialPartForm'
  | 'originalPartDetails'
  | 'currencies'
>;

/**
 * DetailsQueryHost fetches original and new part details and decides whether to show <EditModal/> or <ExtractionDecisionModal/>
 */
export function DetailsQueryHost({ partNode, mutateRemovePartDetailsFromNode, handleClose, ...props }: Props) {
  const [userExtractionDecision, setUserExtractionDecision] = useState<'EXTRACT' | 'EDIT_TOGETHER' | undefined>(
    undefined
  );

  const { currencies, preferredCurrency } = useTenant();
  const { customAttributesNames } = useUser();

  const currenciesToUse = currencies || (preferredCurrency && [preferredCurrency]) || ['GBP'];

  const {
    data: originalPartDetails,
    isFetching: isFetchingOriginalPartDetails,
    isError: isErrorOriginalPartDetails,
    error: errorOriginalPartDetails,
  } = useQuery(
    ['originalPartDetails', partNode.svtPartID],
    () => getPartDetails(partNode.svtPartID!), // we check if this is enabled in the options
    {
      enabled: Boolean(partNode.svtPartID), // Don't fetch if part number empty. TODO: migrate to SvtId
      refetchOnMount: true,
      // onSuccess: () => setUserPressedContinue(true), // TODO: bring back once we move tests to async. Remove effect.
    }
  );

  const [svtPartIDToAssignToANode, setSvtPartIDPartNumberToAssignToANode] = useState<string>();

  const [userPressedContinue, setUserPressedContinue] = useState(false);

  useEffect(() => {
    // Using effect instead of onsuccsess in originalDetails query to avoid re-writing tests to async
    if (originalPartDetails) setUserPressedContinue(true);
  }, [originalPartDetails]);

  const handleCloseAndResetState = () => {
    setSvtPartIDPartNumberToAssignToANode(undefined);
    setUserPressedContinue(false);
    handleClose();
  };

  const {
    data: newPartDetails,
    isLoading: isLoadingNewPartDetails,
    isFetching: isFetchingPartDetails,
    isError: isErrorNewPartDetails,
    error: errorNewPartDetails,
  } = useQuery(['newPartDetails', svtPartIDToAssignToANode], () => getPartDetails(svtPartIDToAssignToANode!), {
    enabled: Boolean(svtPartIDToAssignToANode), // Not set until user clicks in dropdown and continue button. TODO: migrate to SvtId
  });

  const allNodesInOriginalPart = originalPartDetails?.modelNodeIDs || [partNode.modelNodeID!]; // Fallback to partNode's modelNodeID if not fetched
  const askForExtractionDecision = !userExtractionDecision && allNodesInOriginalPart?.length > 1;
  const editedNodes = userExtractionDecision === 'EDIT_TOGETHER' ? allNodesInOriginalPart : [partNode.modelNodeID!];
  const numberOfOccurrences = allNodesInOriginalPart?.length;

  if (isFetchingOriginalPartDetails) {
    return (
      <MUIDialog fullWidth PaperProps={{ style: { overflow: 'visible', maxWidth: 300 } }} onClose={handleClose} open>
        <FlexColumn>
          <MUITypography sx={{ fontSize: '1.5rem', textAlign: 'center', marginTop: '10px' }}>
            Loading part details...
          </MUITypography>
          <LoadingSpinner width="300" height="100" viewBox="0 25 100 50" />
        </FlexColumn>
      </MUIDialog>
    );
  }

  let initialPartForm: PartDetailsForm | undefined;
  if (originalPartDetails) {
    initialPartForm = partDetailsToPartForm(originalPartDetails, currenciesToUse);
  }

  return (
    <>
      {askForExtractionDecision && originalPartDetails ? (
        <ExtractionDecisionModal
          modalOpen={props.modalOpen}
          handleClose={handleCloseAndResetState}
          originalPartDetails={originalPartDetails}
          handleExtraction={async () => {
            await mutateRemovePartDetailsFromNode({ editedNodes });
            handleClose();
          }}
          setUserExtractionDecision={setUserExtractionDecision}
          numberOfOccurrences={numberOfOccurrences}
        />
      ) : (
        <EditModal
          {...props}
          svtPartIDToAssignToANode={svtPartIDToAssignToANode}
          setSvtPartIDToAssignToANode={setSvtPartIDPartNumberToAssignToANode}
          originalPartDetails={originalPartDetails}
          newPartDetails={newPartDetails}
          partNode={partNode}
          handleClose={handleCloseAndResetState}
          isLoadingPartDetails={isLoadingNewPartDetails}
          isFetchingPartDetails={isFetchingPartDetails || isFetchingOriginalPartDetails}
          editedNodes={editedNodes}
          userPressedContinue={userPressedContinue}
          setUserPressedContinue={setUserPressedContinue}
          initialPartForm={initialPartForm}
          customAttributesNames={customAttributesNames}
          currencies={currenciesToUse}
        />
      )}
      <MUIPortal>
        <Toast
          dependency={isErrorNewPartDetails}
          severity="error"
          title="Error getting the part details"
          message={reactQueryGetErrorMessage(errorNewPartDetails)}
        />
        <Toast // I'm not sure if we need to display this at all
          dependency={isErrorOriginalPartDetails}
          severity="error"
          title="Error getting original part details for edit modal"
          message={reactQueryGetErrorMessage(errorOriginalPartDetails)}
        />
      </MUIPortal>
    </>
  );
}
