import type { PartNode } from '@samsonvt/shared-types/productLambda';
import { ModelType } from '@samsonvt/shared-types/productsTable';
import { makePartUrl } from './partUrl';

export const transformChildPart = (part: PartNode, productId: string, selectedPart: any, modelType?: ModelType) => {
  const isProductOption = part?.objectType === 'product-option';

  if (!isProductOption) {
    return {
      name: part.name,
      url: makePartUrl(productId, part.id, {
        group: selectedPart?.pathHash,
        mesh: part.glbIds?.[0],
        modelType,
      }),
      data: part,
    };
  }

  return part.children.map((item) => {
    const { name, id, glbIds } = item;
    return {
      name,
      url: makePartUrl(productId, id, {
        group: selectedPart?.pathHash,
        mesh: glbIds?.[0],
        modelType,
      }),
      id,
      data: part,
    };
  });
};

export const transformParentPart = (part: PartNode, productId: string, modelType?: ModelType) => ({
  name: part.name,
  url: makePartUrl(productId, part.id, { modelType }),
  data: part,
});
